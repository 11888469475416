import { useAnimationFrame } from "framer-motion";
import { useEffect, useRef, useState } from "react";

export const LogoAnim = () => {
  const ref = useRef(null);
  const cnt = useRef(2);
  const [animationDone, setAnimationDone] = useState(false);

  useAnimationFrame((time, delta) => {
    if (animationDone) return;

    let fr = Math.floor(cnt.current / 2);
    let cur = ref.current.querySelector("#_" + fr);
    let prev = ref.current.querySelector("#_" + (fr - 1));

    cnt.current++;

    if (!cur) {
      cnt.current = 2;
      setAnimationDone(true);
    } else {
      if (cur) cur.style.visibility = "visible";
      if (prev) prev.style.visibility = "hidden";
    }
  });

  return (
    <svg
      ref={ref}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      width="100%"
      // height="847.56px"
      style={{ maxWidth: "80vh" }}
      viewBox="0 0 847.559 847.56"
      enableBackground="new 0 0 847.559 847.56"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="810.5"
          y1="757.3"
          x2="204.4"
          y2="407.4"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".2" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="677"
          y1="743.9"
          x2="425.5"
          y2="410.1"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".2" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="661.9"
          y1="723.7"
          x2="463.4"
          y2="460.4"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".2" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-4"
          x1="639.1"
          y1="665"
          x2="467.7"
          y2="526.2"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".2" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-5"
          x1="626.6"
          y1="655.5"
          x2="437"
          y2="502"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".2" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-6"
          x1="619.4"
          y1="650.3"
          x2="402.3"
          y2="474.5"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".2" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-7"
          x1="622.1"
          y1="654.7"
          x2="397.3"
          y2="459.3"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".1" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-8"
          x1="611.9"
          y1="649.8"
          x2="342.9"
          y2="407.6"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".2" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-9"
          x1="614.5"
          y1="663.3"
          x2="287.1"
          y2="335.9"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".2" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-10"
          x1="590.1"
          y1="651.8"
          x2="301.8"
          y2="320.1"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-11"
          x1="597.4"
          y1="671.4"
          x2="313.6"
          y2="321"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-12"
          x1="694.7"
          y1="763.4"
          x2="246.1"
          y2="265.2"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-13"
          x1="941.1"
          y1="1172.7"
          x2="-32.8"
          y2="-218.1"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-14"
          x1="596.3"
          y1="670.7"
          x2="395.6"
          y2="361.6"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-15"
          x1="641.4"
          y1="752.6"
          x2="382.6"
          y2="304.4"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-16"
          x1="678.1"
          y1="884.3"
          x2="364.9"
          y2="212.6"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".6" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-17"
          x1="595"
          y1="730.7"
          x2="397.9"
          y2="266.4"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-18"
          x1="734"
          y1="1046.5"
          x2="332.3"
          y2="100.1"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".6" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-19"
          x1="632.6"
          y1="824.2"
          x2="369.5"
          y2="173.1"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-20"
          x1="541.5"
          y1="775.3"
          x2="424.7"
          y2="225.5"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-21"
          x1="508.9"
          y1="605.6"
          x2="456.9"
          y2="288.6"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#f7d8de" />
          <stop offset=".4" stopColor="#f6d3d9" />
          <stop offset=".5" stopColor="#f5c5cc" />
          <stop offset=".6" stopColor="#f3aeb6" />
          <stop offset=".7" stopColor="#ef8e97" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-22"
          x1="495.7"
          y1="549.6"
          x2="432.8"
          y2="255.2"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset=".2" stopColor="#f2939c" />
          <stop offset=".3" stopColor="#f7bac3" />
          <stop offset=".4" stopColor="#fbd2db" />
          <stop offset=".4" stopColor="#fcdbe4" />
          <stop offset=".6" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-23"
          x1="480.6"
          y1="516.2"
          x2="400.2"
          y2="249"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset="0" stopColor="#eb6670" />
          <stop offset="0" stopColor="#ee757f" />
          <stop offset=".1" stopColor="#f3949d" />
          <stop offset=".2" stopColor="#f7acb5" />
          <stop offset=".3" stopColor="#fabdc6" />
          <stop offset=".3" stopColor="#fbc7d0" />
          <stop offset=".4" stopColor="#fccad3" />
          <stop offset=".6" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-24"
          x1="548.9"
          y1="626.2"
          x2="353"
          y2="241.7"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".2" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcdbe0" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-25"
          x1="524.2"
          y1="634.8"
          x2="325.3"
          y2="227.1"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".1" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fce0e4" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-26"
          x1="545.7"
          y1="692.7"
          x2="301"
          y2="167.9"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".2" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fce2e9" />
          <stop offset=".6" stopColor="#f3a4aa" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-27"
          x1="669.5"
          y1="934.8"
          x2="307.2"
          y2="158"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".4" stopColor="#eb6670" />
          <stop offset=".7" stopColor="#fcecee" />
          <stop offset=".8" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-28"
          x1="579.5"
          y1="675.1"
          x2="286.8"
          y2="135.2"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-29"
          x1="526.5"
          y1="499.6"
          x2="301.7"
          y2="126.4"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".4" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-30"
          x1="690.7"
          y1="324.3"
          x2="193.4"
          y2="218.6"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".2" stopColor="#eb6670" />
          <stop offset=".3" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-31"
          x1="718.7"
          y1="137"
          x2="184.2"
          y2="260.4"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".2" stopColor="#eb6670" />
          <stop offset=".3" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-32"
          x1="581.9"
          y1="83.5"
          x2="230.1"
          y2="262.8"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-33"
          x1="430"
          y1="26.4"
          x2="223.1"
          y2="264.4"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-34"
          x1="368.7"
          y1="19.8"
          x2="205.7"
          y2="291"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-35"
          x1="346.1"
          y1="41.7"
          x2="208.2"
          y2="290.6"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-36"
          x1="364.2"
          y1="10.5"
          x2="187.7"
          y2="317"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".4" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-37"
          x1="314.5"
          y1="4.3"
          x2="212.6"
          y2="359.7"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".3" stopColor="#f1949b" />
          <stop offset=".4" stopColor="#f7c3c8" />
          <stop offset=".5" stopColor="#fbe1e4" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".5" stopColor="#f9d4d8" />
          <stop offset=".6" stopColor="#f199a0" />
          <stop offset=".7" stopColor="#ed747d" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-38"
          x1="312.5"
          y1="5.6"
          x2="249.2"
          y2="440.9"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-39"
          x1="292.5"
          y1="-15.7"
          x2="280.4"
          y2="507.7"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".6" stopColor="#f4abb1" />
          <stop offset=".6" stopColor="#ed7a83" />
          <stop offset=".7" stopColor="#eb6771" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-40"
          x1="258.3"
          y1="50"
          x2="335.3"
          y2="536.6"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-41"
          x1="251"
          y1="35.8"
          x2="374.8"
          y2="551.7"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-42"
          x1="225.8"
          y1="53.5"
          x2="412.2"
          y2="587"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-43"
          x1="198.4"
          y1="53"
          x2="443.2"
          y2="629.6"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-44"
          x1="203.9"
          y1="59.4"
          x2="462.7"
          y2="669.3"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".5" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-45"
          x1="195.2"
          y1="22"
          x2="471.9"
          y2="706.9"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".6" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-46"
          x1="202.1"
          y1="82.2"
          x2="480.6"
          y2="738.5"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".6" stopColor="#fcecee" />
          <stop offset=".7" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-47"
          x1="233.3"
          y1="157.1"
          x2="497.4"
          y2="779.3"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".6" stopColor="#fcecee" />
          <stop offset=".8" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-48"
          x1="232.9"
          y1="160.7"
          x2="512.9"
          y2="820.3"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".6" stopColor="#fcecee" />
          <stop offset=".9" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-49"
          x1="213.9"
          y1="120.3"
          x2="556.9"
          y2="928.3"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".3" stopColor="#eb6670" />
          <stop offset=".7" stopColor="#fcecee" />
          <stop offset=".9" stopColor="#eb6670" />
          <stop offset="1" stopColor="#d30334" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-50"
          x1="227.3"
          y1="221.5"
          x2="483.5"
          y2="683.8"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".5" stopColor="#eb6670" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-51"
          x1="271.3"
          y1="255.9"
          x2="554.6"
          y2="709.2"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".5" stopColor="#eb6670" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-52"
          x1="282.2"
          y1="261.1"
          x2="551.7"
          y2="745.6"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".5" stopColor="#eb6670" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-53"
          x1="267.7"
          y1="245.2"
          x2="606"
          y2="808.2"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".5" stopColor="#eb6670" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-54"
          x1="268.7"
          y1="246.9"
          x2="632.6"
          y2="852.5"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".5" stopColor="#eb6670" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-55"
          x1="269.6"
          y1="248.7"
          x2="631.5"
          y2="850.9"
          gradientTransform="translate(0 845.9) scale(1 -1)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#d30334" />
          <stop offset=".5" stopColor="#eb6670" />
          <stop offset="1" stopColor="#fff" />
        </linearGradient>
      </defs>
      <g style={{ visibility: "hidden" }} id="_1">
        <polygon
          points="592.2 211.8 593.2 213.6 595 214.6 593.2 215.7 592.2 217.4 591.2 215.7 589.4 214.6 591.2 213.6 592.2 211.8"
          fill="#fcecee"
          fillRule="evenodd"
          isolation="isolate"
          opacity=".4"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_2">
        <polygon
          points="592.2 211.8 593.2 213.6 595 214.6 593.2 215.7 592.2 217.4 591.2 215.7 589.4 214.6 591.2 213.6 592.2 211.8"
          fill="#fcecee"
          fillRule="evenodd"
          isolation="isolate"
          opacity=".7"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_3">
        <polygon
          points="592.2 211.8 593.2 213.6 595 214.6 593.2 215.7 592.2 217.4 591.2 215.7 589.4 214.6 591.2 213.6 592.2 211.8"
          fill="#fcecee"
          fillRule="evenodd"
          isolation="isolate"
          opacity=".8"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_4">
        <polygon
          points="592.2 208.4 594.5 212.3 598.4 214.6 594.5 216.9 592.2 220.8 589.9 216.9 586 214.6 589.9 212.3 592.2 208.4"
          fill="#fcecee"
          fillRule="evenodd"
          isolation="isolate"
          opacity=".9"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_5">
        <path
          d="M592.2,202.8l2.9,6.3c.8,1.7,.9,1.8,2.6,2.6l6.3,2.9-6.3,2.9c-1.7,.8-1.8,.9-2.6,2.6l-2.9,6.3-2.9-6.3c-.7-1.6-1-1.9-2.6-2.6l-6.3-2.9,6.3-2.9c1.5-.7,1.9-1,2.6-2.6l2.9-6.3Z"
          fill="#fcecee"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_6">
        <path
          d="M610.6,214.6l-8.2,2.6c-3.4,1.1-5.9,2.2-7,5.8l-3.2,10-3.1-10c-1.3-4.1-3.3-4.6-7.2-5.9l-8.1-2.5,8.1-2.5c3.9-1.2,5.9-1.7,7.2-5.9l3.1-10,3.2,10c1.1,3.5,3.7,4.7,7,5.8l8.2,2.6Z"
          fill="#fcecee"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_7">
        <path
          d="M592.2,247.7l-4.5-25.3c-.4-2.2-1.8-3-3.9-3.4l-24.7-4.4,24.7-4.4c2.4-.4,3.5-1.3,3.9-3.7l4.4-25,4.4,25c.4,2.4,1.6,3.3,3.9,3.7l24.7,4.4-24.7,4.4c-2.1,.4-3.5,1.2-3.9,3.4l-4.5,25.3Z"
          fill="#fcecee"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_8">
        <path
          d="M592.2,208.8h0s-3.2-2.2-3.2-2.2c-4.1-2.9-4-5.6-3.3-9.9l6.5-36.9,6.5,36.9c.8,4.3,.8,7-3.3,9.9l-3.2,2.2h0Zm5.7,5.7h0s2.2,3.2,2.2,3.2c2.9,4.1,5.6,4,9.9,3.3l36.9-6.5-36.9-6.5c-4.3-.8-7-.8-9.9,3.3l-2.2,3.2h0Zm-5.8,5.8h0s3.2,2.2,3.2,2.2c4.1,2.9,4,5.6,3.3,9.9l-6.5,36.9-6.5-36.9c-.8-4.3-.8-7,3.3-9.9l3.2-2.2h0Zm-5.7-5.7h0s-2.2-3.2-2.2-3.2c-2.9-4.1-5.6-4-9.9-3.3l-36.9,6.5,36.9,6.5c4.3,.8,7,.8,9.9-3.3l2.2-3.2h0Z"
          fill="#fcecee"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_9">
        <path
          d="M592.2,202.6l-3.5-2.1c-3.4-2-3.7-4.9-3.1-8.5l6.6-37.2,6.6,37.2c.6,3.6,.3,6.5-3.1,8.5l-3.5,2.1h0Zm12,12l2.1-3.5c2-3.4,4.9-3.7,8.5-3.1l37.2,6.6-37.2,6.6c-3.6,.6-6.5,.3-8.5-3.1l-2.1-3.5h0Zm-24,0l-2.1-3.5c-2-3.4-4.9-3.7-8.5-3.1l-37.2,6.6,37.2,6.6c3.6,.6,6.5,.3,8.5-3.1l2.1-3.5h0Zm12,12l-3.5,2.1c-3.4,2-3.7,4.9-3.1,8.5l6.6,37.2,6.6-37.2c.6-3.6,.3-6.5-3.1-8.5l-3.5-2.1Z"
          fill="#fcecee"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_10">
        <path
          d="M578.1,161.8l16.9,36.3c1.1,2.4,1.1,4.7-.7,6.8l-3.5,4.2-5.5-2c-2.9-1.1-3.4-2-3.7-5.1l-3.5-40.2h0Zm27.1,42.1l39.9-3.5-35.8,16.7c-2.7,1.3-5.1,1.3-7.5-.7l-4-3.3,1.9-5.3c1.1-3.1,2.3-3.6,5.5-3.9h0Zm-2.3,23.3l3.5,40.2-17.2-36.8c-1.2-2.6-.8-4.1,1-6.3l3.5-4.2,5.2,1.9c2.8,1,3.7,2.2,3.9,5.2h0Zm-23.1-2l-40.4,3.5,36.8-17.2c2.3-1.1,4-1,6,.7l4.5,3.8-2,5.4c-1,2.8-2,3.5-5,3.8Z"
          fill="url(#linear-gradient)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_11">
        <path
          d="M565.9,184.4l22.6,18.3c1.6,1.3,2.6,2.7,2.6,4.8v6.4c0,0-4.8-1-4.8-1-2.6-.5-4-1.3-5.4-3.7l-14.9-24.9h0Zm31.2,19.1l25.3-15.2-18.1,22.3c-1.8,2.3-2.8,2.9-5.8,3l-5.4,.3,1.1-6.4c.3-2,1.2-3.1,3-4.2h0Zm6,15.8l15.3,25.5-22.8-18.5c-1.8-1.4-2.4-2.3-2.4-4.6v-5.4c-.1,0,5.5,.4,5.5,.4h.4c2.1,.2,3,.7,4,2.5h0Zm-16.4,6.7l-24.8,14.9,18.1-22.3c1.9-2.3,2.8-2.9,5.8-2.8h4.8c0,.1-.5,5.2-.5,5.2-.3,2.6-1.1,3.7-3.3,5Z"
          fill="url(#linear-gradient-2)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_12">
        <path
          d="M576,195.3l13.6,8.3c2.8,1.7,4.2,1.8,7.2,.6l14.7-5.8-8.2,13.4c-1.9,3.1-2,4.1-.7,7.5l5.8,14.7-13.2-8.1c-3.5-2.2-4.6-2.1-8.5-.5l-13.9,5.5,8.1-13.2c2-3.1,2.3-4,.9-7.4l-5.9-14.8Z"
          fill="url(#linear-gradient-3)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_13">
        <path
          d="M562.5,208.7c6.9-1.1,16.8-2.3,23.2,3.2,4-1.7,7.5-3.7,10.6-5.9,.1,1.4-.3,2.9-1.2,5.1,1.5-.7,2.8-1.1,4.8-1.3-2.4,4.1-2.6,4.7-4.7,9.4,8,4.1,9.9,15.2,9.7,23.6-11-5.2-15.8-12.7-22.7-12.2-7.4,3.7-17.8,6.4-19.8,12.8-1.5-3.2-.5-6.1,2.1-10.1,1.9-2.8,3.7-5.9,4.4-8.8,.3,1.7,.1,3.9-.3,6.1,2.5-3.5,2.8-4,5.3-7.5,1.2-7.2-7.7-6.5-11.4-14.3Z"
          fill="url(#linear-gradient-4)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_14">
        <path
          d="M560.3,209.7c2.5-.7,4.4-.4,6.2,1.3,2.8,.2,4.4,2.1,5,5,1.8,.9,2,3.5,2.2,6,4.3-1.8,8.3-7.6,12.6-9.4,0,1-1.2,4-1.7,6,1.8-1.4,2.5-1.8,3.9-2.4-.4,1.9-.6,2.5-1.6,3.9,2.3-1.1,5.7-1.7,7-1.9-2.6,4.4-7.7,6.6-10,11.6,3,0,7-.7,8.5,1.6,3.4,0,5.7,.7,7.4,4,2.4,.5,3,4.8,2.9,8.6-12.4-5-25.3-2.7-32.7-2.1-7.9,4-19.5,8-22.1,14.4-1.6-3.5-.3-7.1,2.5-11.4,2-3,4.6-6.5,5.3-9.6,.3,1.8,.1,4.2-.4,6.5,2.7-3.8,3-4.3,5.7-8,1.3-7.7,3.2-15.9-.7-24.3Z"
          fill="url(#linear-gradient-5)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_15">
        <path
          d="M554.9,211.3c2.6-.7,2.8,4.1,3.4,7.4,3-2.8,5.2-2.6,5,3.7,3.1-.9,3.6,2,2.4,6.8,4.4-1.9,8.3-9,12.7-10.9,0,1-1.4,4.7-1.9,6.7,1.8-1.4,5.6-3.6,8.1-4.3-.4,1.9-3.4,5.5-4.9,6.9,2.9-1.1,6.8-2.2,8.1-2.4-2.7,4.5-9.2,6.8-11.6,11.9,4.4-1.5,10.5-2.9,8.8,1.7,6.1-.8,10.9-.1,7.6,4.2,2.5,.6,7.8-.1,8.9,3.5-10.4,5-22.8-.9-39.6,3.3-8.1,4.1-20.1,8.5-22.8,14.9-1.6-3.6-.3-7.3,2.6-11.7,2.1-3.1,4.7-6.7,5.5-9.9,.3,1.8,.1,4.3-.4,6.7,2.7-3.9,3.1-4.4,5.8-8.3,1.3-8-2.1-13.1,2.1-30.1Z"
          fill="url(#linear-gradient-6)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_16">
        <path
          d="M549.7,215.8c1.5,4.6-2.9,9.8-2.4,13.1,1.5-1.5,4-4.2,5.6-4.9,1.1,1.2-.4,4.9-.5,8.4,1.1-.3,1.7-.7,2.5-1,1,.9,.8,4.6,.2,7.7,4.3-2.1,7.6-9.7,12.8-11.7,.1,1-1.8,5.1-2.3,7.1,2.5-2.5,7.3-5.3,10-6-.3,2-4.8,7.1-6.8,8.5,3-.8,7.5-3.1,9.1-3-2.4,5.5-10,7.4-12.2,12.7,3.3-1.3,7.9-2.7,10.1-1.9-.5,1-1,2.3-1.2,3.3,4.5-1.1,8.7-1.2,10.9-.5-1.2,1.3-2.2,2.9-3.2,4.3,2.9-.3,10-4.8,16.5-2.2-26.1,20.1-30.4,5.4-46.9,10.2-8,4.4-20.4,10-22.9,16.5-1.8-3.5-.6-7.4,2.9-12.6,2.1-3.1,4.6-7.4,5.3-10.7,.4,1.8,.4,4.8-.3,7.3,2.6-4,2.9-4.5,5.5-8.5-.9-13.7-13.6-14.1,7-36Z"
          fill="url(#linear-gradient-7)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_17">
        <path
          d="M534.6,219.9c1.7,4.7-.3,14.4,.3,17.8,1.4-1.6,5.6-5.3,7.4-7.1,1.3,2.6-.3,7.6-.7,11.5,1.1-.4,3-2,4-3.2,1.1,1.6-.5,6.7-1,9.9,4.4-2.3,9.6-13.2,16.4-15.3,.1,2-5.3,8.1-5.7,10.2,2.4-2.5,8-8.3,13.4-9.4-.4,2.7-7.1,9.6-10,11.8,3-.9,10.7-4.7,12.4-4.7-2.3,5.7-13.1,9.2-15.1,14.7,3.3-1.4,10.5-3.7,12.8-3-1.5,1-3.4,3-3.7,4,5.4-2.3,12.3-3.2,14.6-2.5-2.3,1.5-5.5,4.7-6.5,6.2,5.6,0,14.3-10.3,21.1-7.9-3,5.1-8.4,12-10.6,14.2-13.2,12.6-29-4.4-47.1,6.5-7.5,4.5-16,10.3-17.8,15.6-1.9-3.5-.3-8.8,3.1-14.2,2-3.2,4.5-7.8,5-11.2,.5,1.9,.6,4.9,0,7.5,2.5-4.2,2.8-4.7,5.3-8.9-1.4-14-14.6-16.1,2.3-42.5Z"
          fill="url(#linear-gradient-8)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_18">
        <path
          d="M525.6,223.5c1.8,4.5-4.2,15.4-3.4,18.7,1.4-1.7,6.8-5.5,9.6-9.1,1.4,2.5-1.1,11-3.2,15.7,3.5-.7,8-3.3,9-4.5,1.2,4.2-3.8,11.9-4.2,15.1,4.2-2.4,8.1-13.5,15.4-15.8,.2,1.2-4.6,7.9-4.8,10,2.3-2.6,8.9-8.3,13.8-9.7-.1,2.1-6.8,8.9-9.5,11.2,3.3-1.6,10.1-5.2,11.8-5.3-2,5.7-13.4,10.7-15.2,16.2,6.2-.6,9.2-5.7,13.2-5.6-.8,2.6-2.5,5.1-4.1,6.2,7.1-.7,8.3-6.4,13.7-7.7-.9,4.5-3.6,9.3-5.6,10.9,8.1-.5,9.3-15.8,14.7-16.7,1.4,8.3-4.1,19.1-6.1,21.3-12.4,13-26.7-1.9-44,9.6-7.2,4.8-15.3,10.8-16.8,16.1-2.1-3.4-.7-8.6,2.4-14.1,1.8-3.3,4-7.9,4.4-11.2,.6,1.8,.8,4.8,.3,7.4,2.3-4.2,2.6-4.8,4.8-9-1.3-8.5-9-16.1-10-26.2-.6-6,.9-11.4,5.3-15.6,2.7-2.6,6.3-5.5,8.5-8.1Z"
          fill="url(#linear-gradient-9)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_19">
        <path
          d="M522.1,227.6c.2,8.7-12.1,16.8-13.8,22.8,3.6-1.8,8.1-3.7,12.4-7,1.3,6.6-7.1,13.1-7.4,20.8,3.5-1.6,7.7-5.1,8.8-6.4,1.9,5.4-5.8,12.8-3.3,17,4.6-2.5,10.5-17,18-20,.3,1.3-5.3,9.3-5.5,11.5,3.6-4.6,10.5-10.6,15.5-12.4-.2,4.2-8.2,11.1-10.9,13.7,3.3-1.9,11-6.6,12.7-6.8-1.7,6.1-14,12.7-15.4,18.5,8.4-1.2,9.2-7.7,13.4-7.9-.4,2,0,4.6-.3,6.1,5.3-2.5,5.2-7.1,9.9-9.8-.4,1.9,.3,5.1-.4,7.4,6.3-4.2,2.4-19.4,8-20.7,2.2,8.6,3.7,18-1.2,25.6-11.8,18.4-29.9,6.1-47.1,19.4-7.1,5.5-15.3,13.4-18.2,18.5-2.4-3.4,.5-9.5,3.3-15.4,1.7-3.6,3.6-8.5,3.8-12,.7,1.8,1.2,4.9,.8,7.7,2.6-5.8,4.8-10.4,2.8-14.3-3.4-6.8-7.3-14.3-8.7-22.9-2-12.3,1-22.2,9.8-26.3,4.2-2,9-4.4,12.9-7.2Z"
          fill="url(#linear-gradient-10)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_20">
        <path
          d="M496.6,230.7c4.8,5.3,.8,23.5-.7,29.6,3.6-1.9,6-8,9.4-14.4,2.2,8.3-3.7,18.5-3.8,26.4,3.5-1.8,5.3-8.1,6.4-9.5,1.5,3.9-.6,11.7-2.1,16.5,1.6-.4,2.8-1.7,4.2-2.8,.7,1.7-.9,6.3-2.2,9.1,4.6-2.7,10.2-17.7,17.7-20.9,.3,1.3-5.2,9.6-5.2,11.9,3.5-4.8,11.4-10.9,16.4-12.8,0,4.3-9,11.3-11.7,14,3.3-2,11-7.1,12.7-7.4-1.5,6.3-13.9,13.4-15.2,19.3,1.1-.2,5.1-4.9,7.4-5.3-.6,1.5-1.1,3.2-1.2,4.2,5.4-3.5,8.6-8.2,11.8-8.5-1.4,2.3-1.9,5.3-3.2,7.6,5.3-2.7,9.9-11.7,16.5-13.3-2.1,3.2-4.4,9.3-5,11.7,8.1-8.7,18.6-14.6,25.2-15.3-7.1,5.2-12.6,13.4-17.3,21.4-11.5,19.1-34.4,4.5-51.5,18.6-7.1,5.8-14.1,15.9-16.9,21.8-2.5-3.4-.6-11.5,1.9-18.1,1.4-3.7,3.4-8.8,3.5-12.4,.8,1.9,1.4,5,1.1,7.8,2.5-6,4.6-10.7,2.4-14.6-3.7-6.8-7.9-14.4-9.6-23.1-2.4-12.4-2-16.7,2-24.3,2.2-4.2,5-9.6,6.9-17.3Z"
          fill="url(#linear-gradient-11)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_21">
        <path
          d="M481.9,245c4.5,5.3,1.4,20.6-.3,26.5,3.6-1.7,4.7-7.6,8.2-13.6,3.4,10.6-1.6,16.3-2,24,3.4-1.6,7.2-10.7,8.4-12,1.6,4.9,1.4,12.6-1,19.6,2.4-1.6,5.2-4.9,6.4-7,1.2,2.9,0,10.4-1.3,13.1,4.6-2.5,12.2-18.3,19.6-21.2,.3,1.2-7.1,10.7-7.2,12.9,3.5-4.5,12-14.3,17.7-15.6-.2,4.1-11,15.2-13.7,17.7,3.3-1.8,12.5-8.6,14.2-8.8-1.7,6-15.1,13.8-16.5,19.6,3.9-.5,8.5-5.4,12.8-5.9-.7,1.5-1.6,4.6-1.4,6.2,5.3-3.2,7-7.2,12.3-8.9-1.4,2.2-.4,7.2-1.7,9.4,6-2,11.2-10.1,17.3-11.8-1.6,3.9-2.6,10.2-3.3,12.4,8.1-8.2,15.4-11.5,21.8-14.6-3,9.5-11.3,18.5-17.8,24.2-12.1,10.6-38.6-3.9-55.6,9.2-7,5.4-15.3,18.5-18.3,24.1-2.3-3.4-.1-11.1,2.5-17.4,1.5-3.6,3.6-8.4,3.8-11.8,.7,1.8,1.1,4.9,.8,7.6,2.6-5.7,3.8-14.2,1.8-18.1-3.4-6.7-11.9-12.4-16.8-22.8-2.9-6.2-3.3-9.4-2.9-12.1,2.3-13.8,11.4-23.8,11.9-24.7Z"
          fill="url(#linear-gradient-12)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_22">
        <path
          d="M460.7,256.1c5.2,3.9,9.6,21.7,8.6,27.5,3.3-3.2,4.7-7.7,5.8-15.5,4.3,9.7,3.9,17.9,4.4,25.2,3.1-1.9,4.7-11.8,5.6-13.1,2,4.4,4.2,13.2,2.8,20,2.1-1.7,5.5-6.5,6.3-8.7,1.4,2.6,1.3,11.9,.3,14.6,4-2.8,9.6-18.6,16.2-22.1,.4,1.1-5.5,10.8-5.4,12.9,2.9-4.6,10.2-14,16.2-15.5,.3,3.9-9.9,14.4-12.1,17.1,2.9-2.1,10.9-9.5,12.5-9.8-.6,7.4-13.4,15.8-14.1,21.3,3.6-.9,12.6-7.9,16.6-8.9-1.4,2.4-1.6,7.5-1.2,9,4.7-3.6,8.9-9.1,14.3-10.9-2,3.4-.5,7.1-1,11.6,5.4-2.5,10.8-9.6,18.2-11.3-2,3.9-3,9.1-3.5,11.8,9.5-3.8,15.6-11.9,22.8-11.4-5.6,9.5-13.9,17.9-21.6,22.9-8.6,5.6-26-1.7-38.9-1.8-7.5,0-12.2,3.3-17.6,8.5-4.1,4-10.6,10.6-15,16.3-2.1,2.6-2.9,4.9-3.2,8.1-4.3-3.6-1.3-10.4,.5-16.6,1-3.5,3.7-8.8,3.5-12,.8,1.6,.9,5.3,.8,7.9,1.9-5.6,5.2-14,3-17.4-3.8-5.9-13.9-13-19.8-17.2-4.6-3.3-9.3-7.9-8.8-15.6,.8-13.2,3.2-17.8,3.7-26.7Z"
          fill="url(#linear-gradient-13)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_23">
        <path
          d="M463.4,269.5c5.8,4.1-6.6,16.8-4.4,25.3,3.5-3.8,6.7-9.6,10.7-13.9,6.2,8.2-3.9,13.3-1.6,21,3.3-2.1,9.3-11.7,10.3-13.3,4.8,5.1-1.5,13-1,21.2,2.2-2,8.1-9.1,9-11.5,5,2.2,2.2,10.1,3.7,14.8,4.4-3.2,8.6-18.7,15.8-22.7,.5,1.2-6.2,13.4-6.1,15.7,4.1-5,8.4-14.6,16-16.9,.5,6-9.1,14.4-11.5,18.5,3.1-2.3,12.7-10.9,14.4-11.3-.9,6.4-14.3,17.7-15,23.8,11.6-1.1,9.5-8.8,17-10-1.5,2.7,2.7,7.1,2.8,12.8,5.1-4.1,5.7-13.1,11.6-15.3-2.1,3.8,3.6,14.1,3.2,19.1,5.9-2.9,7.6-17.2,15.7-19.2-2.1,4.3,0,20.4-.6,23.4,6.5-6.3,7.1-19.9,15.8-22.6-.8,25.5-3.6,38.3-16.4,42.6-5.9,2-15.9-8.9-23-13.6-6.8-4.5-14.1-5.1-20.5-5-8.2,.1-13.4,3.9-19.1,9.8-4.4,4.5-11.4,12-16.1,18.3-2.2,3-3.8,5.3-4.8,8.7-3.5-4-.3-11.1,1.6-18,1-3.9,3.7-8.9,4.4-12.4,1,1.8,.6,4,.6,6.8,1.3-4.2,3.9-13.5,4.8-18.7,2-11.2-15.3-10.4-29.2-10.9-18.2-.7-6.6-33,11.9-46.3Z"
          fill="url(#linear-gradient-14)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_24">
        <path
          d="M428.5,323.5c6.1,5.7,2.9,13.2,6.3,16.5,3.3-4.1,.7-19,9.9-29.5,1.4,3.8,.6,7.9,2,11.2,4.4-9.5,10-14.4,16.4-17.6-3.3,7.1-1.7,9.8-3.1,15.7,5.5-5.4,8.9-9.9,15.8-11.6-2.8,4.6-4.4,9.9-3.1,13.5,2.9-2.5,6.6-5.5,9.9-6.6-.6,3.8,.5,4.7,1.2,7,4.3-3.5,8.4-17.3,15.6-21.9,1.2,2.9-6.8,14.1-6.5,16.5,3.9-5.3,8.3-16.4,16-19.3,.8,6.2-9.1,16.2-11.3,20.7,3.1-2.6,11-11.9,14.2-12.4-.6,6.7-12.6,17.2-12.9,23.5,4.1-3,11.6-3.9,14-7.2,3.3,3.3,2.1,6.6,4.3,9.8,5.2-2.1,6.9-7.2,9.4-11.8,3.5,8.1,.5,12.6,2.4,18.6,5.6-4.2,8.8-9,12.3-14.7,3.9,7.3-2.2,19.3-1.3,25.5,7-2.6,7.6-3.4,14.8-10.8-.6,14.2-10,25.8-10.4,29,8.3-2.1,13.4-1.7,21.5-7.8-4.1,17.4-25.9,29.5-42.3,31.4-6.2,.7-13.3-.6-9.8-7.2,5.3-10.1,13.6-18.7,10.6-23.5-5.9-9.5-15.3-18.9-21.9-18.5-11.3,.8-23.4,12.4-36,31.2-2.1,3.2-3.6,5.6-4.4,9.3-3.9-3.9-.9-11.4,.6-18.5,.9-4.1,3.4-9.4,3.8-13,1.1,1.8,.8,4.1,1,7,1.6-6.7,6.7-16.1,5.2-22.9-1.5-6.9-18.4-7.9-23.6-3.2-6.9,6.2-2.5,17.9-6.3,26.1-7.9,17-17.9-16.1-14.3-34.5Z"
          fill="url(#linear-gradient-15)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_25">
        <path
          d="M404.9,367.2c4.2,8.1,7.4,13.5,11.8,16.2,1.8-10.8-6.4-23.6,5.2-40-.7,6.5,.6,17.7,2.5,21.3,4.2-10.9,5.5-33,17.4-39.1-2.5,7.2-3,11.9-2.9,16.8,5.8-6.3,9.8-21.4,18.8-24.1-2.7,5.3-3.5,11.9-1.7,15.9,3.1-3,3.9-11.6,12.9-14.9-1.1,3.1-3.5,10.2-.7,13.2,2.1-4.7,5.2-7.6,7.1-7.9-.4,3.5,2,9.6,2.2,10.2,4.6-4.2,8.2-19.8,15.9-25.3,1.5,3.2-6.1,14-6.2,18.7,4-6.2,8.1-18.7,16.5-22.4,1.3,6.8-8.8,19.6-11,24.7,3.3-3.1,10.3-15.4,13.9-16.2,.4,8.9-10.7,18.8-10.6,26.4,3.8-2.9,10-4.5,12.8-8.2,1.3,2.6,1.2,6.2,3.3,8.1,6-.8,9.2-4.2,10.4-6.1,3.9,3.4-.5,14.6,2.1,18,5.6-2.7,10.4-4.7,12.8-9.9,4.4,8.7-7.7,23.7-7.6,31,7.7-.3,13.6-4.1,19.1-8.7-1.9,19.9-15.2,24.2-21.3,32.2,7.5,1.7,12.4,2.7,21.7-.2-9.3,17.5-25.9,21.4-38.3,23.2,4.7,8.1,18.5,12.8,30.7,14-17,9.8-31.9,6-49.3,2.1-13-2.9-14.9-10.4-5.6-19.1,12.5-11.8,20.6-21.1,20.9-26.6,1-16.1-.8-35.6-8-34.6-12.5,1.6-25.2,15.3-37.9,36.9-2.1,3.6-3.6,6.5-4.3,10.6-4.5-4.1-1.7-12.6-.5-20.6,.7-4.6,3.1-10.6,3.4-14.7,1.3,1.9,1.1,4.5,1.5,7.7,1.4-7.5,5.4-19.8,4.8-27.6-1.3-16-25.2,11.2-30.7,16.8-4.4,4.4-7.5,15.4-7.1,25.6,.3,6.9,2.1,13.7,.6,17.4-3.1,7.7-7.5,5.7-12.8,1.1-8.1-7-15.1-25.5-11.6-41.8Z"
          fill="url(#linear-gradient-16)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_26">
        <path
          d="M382.7,430.6c4.6-14.2,19.6-16.3,24-16.7-1.9-7.4-10-11-10.4-22,2.4,2.7,9.8,3.4,14,4-1.8-8.8-1.6-23.1,4.3-31.7,.3,8.4,.4,14.5,1.9,18,3.1-3.2,6-31.3,20.4-39.7-1.7,3.4-6.9,15.3-7.3,20.9,2-4.9,14.9-23.1,25.6-27.3-.2,2.1-6.6,8.9-5,14.5,4.2-5.8,9.4-11,14-12.3-.3,7.8,5.3,11.1,5.3,11.2,4.6-4.4,5.5-18.3,13.2-24.2,1.6,3.2-5.1,16.2-5.1,21.1,4.4-6.5,7-24.7,17.1-28.4,2,8.1-10.3,20-11.2,30.6,3.3-3.3,8.7-18.6,14.5-21,1.3,10.2-9.3,18.7-9,26.6,3.1-2.3,14.5-.8,18.5-7.1,4.9,5.2,4.1,16.3,5.2,19.9,7.8-5.8,11.8-12.8,13.3-14.9,2.4,10.9-2.6,22.1-3.9,30.5,6.1-1.1,14.1-12,15.8-15.8,2.3,17.9-9.5,27.9-13,34.6,7.6,.7,15.4-.5,22.7-4.5-4.5,18-22.9,19.5-28.4,25.5,7,4.2,16.1,9.6,21.4,12.1-9.7,4.7-23,3.5-31.2,2.1,7.3,11.4,11.7,25,6.3,37.1-6.2-11.8-14.8-19.2-27.6-26.5-10.5-6-12-6.5-4.4-18.1,7.3-11.1,18.1-17.6,19-23.3,2.9-19.7,.5-35-7-33.8-16.5,2.7-28.5,19.7-37.8,35.3-1.2,2-4,7.5-4.7,10.3-2.8-2.4-3.5-10.6-2.3-16.9,1.5-8.1,4.7-21.6,4.9-26.5,1.4,2.1,.9,4.5,1.4,7.8,1.2-7.8,2.4-10.8,1.5-18.8-1.8-16.4-23.9,13.3-29.5,19.2-4.4,4.6-8.8,18.1-7.6,28.6,2.5,21.9-13.4,13.4-38.9,19.6Z"
          fill="url(#linear-gradient-17)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_27">
        <path
          d="M396,467.6c-5.2-14.2,1.6-30.7,7.9-38-5.9-1.2-9.7-1.3-15.3-.2,2.2-13.5,12.2-13.5,16.9-13.8-2-7.8-8.8-11.4-6.2-24,2.5,2.8,5.6,4.3,10.1,5-1.9-9.2-1.7-24.4,4.6-33.4,.3,8.8,.5,15.3,1.9,19,3.2-3.4,6.4-33,21.5-41.8-1.8,3.6-7.3,16.1-7.7,22,2.1-5.1,14.9-23.4,25.3-28.3-.2,2.2-8.6,11.7-5.5,17.3,4.5-6.1,8.7-11.8,13.5-13.2-.3,8.2,7.5,13.6,7.5,13.7,4.8-4.7,6.5-23.4,14.7-29.6,1.7,3.4-4.9,17.1-4.9,22.2,4.1-6.8,7.4-26,18-29.9,2.1,8.5-10.8,21-11.8,32.2,3.5-3.4,10.6-21.1,14.4-22,1.3,10.7-10.4,24.6-10.1,32.9,1.2-1,19.5-1.4,24.1-9.8,5.2,5.5,1.2,14.6,2.4,18.3,11.2-5.2,16.3-11.8,17.8-14,1.3,14-6.6,21.6-8,30.4,6.4-1.1,17-11.5,19.2-13.5,1,17.5-12.5,26.3-16.3,33.3,8.4,3,16.7-1.3,25.8-4-4.7,18.9-26,19.8-31.8,26.1,7.4,4.4,15.8,14.6,20.1,19.2-10.5,3.5-21.8-2.6-30.4-4.2,4.8,18.8,8.5,37.2-1.5,47.9-3.9-12.7-8.6-25.4-17.2-31.9-.5,16.2-12.5,43.1-32.9,46.3,14.2-21.5,16.6-35.5,16.7-49.9,.1-18.7,25.9-34.7,26.1-40.7,.5-17.4-2-38.6-9.8-37.3-20.7,3.3-33.8,30.9-42.2,47.8-1.1,2.2-2.2,4.4-3,6.8-3-2.5-3-8.5-2-15.2,1.4-9.2,5.5-20.3,5.7-25.4,1.5,2.2,.8,3.4,1.4,6.9,1.3-8.2,5-19.8,4-28.3-1.9-17.3-27,13-32.8,19.2-4.6,4.9-6.3,15.8-5.1,26.8,1.3,11.6,.4,20.4-5.8,25.9-6.1,5.4-13.9,13.7-19.3,29.2Z"
          fill="url(#linear-gradient-18)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_28">
        <path
          d="M399.3,505.9c-9.2-11.1-9.2-35.3-2.8-52.5-5.1,2.7-9.5,7.5-14,16.3-2.3-14.8,6.4-29,12.8-35.8-1.6-4.1-7.4-4-11.7-3.4,2.7-9,10.2-13.1,15.5-14.6-1.2-9.3-2.5-13.8-5.7-21.1,4.4-.4,9.7,1,13.3,2.9,3.5-3.6-2.4-18.7,5.8-32.2-2,3.9,6.2,7.9,8.2,12.4,2.3-5.6,5.1-19.9,14.3-29-.2,2.4-3.6,14,2.6,18.6,4.9-6.7,10.8-17.2,16-18.8-.4,8.9,11.7,15.6,11.7,15.7,5.2-5.1,3.7-25.4,15.1-33,1.9,3.7-5,18.8-5,24.4,5.1-7.4,7.2-25.6,16.3-31.4,2.8,9.5-7.3,22.3-8.4,34.4,3.8-3.7,7.4-21.6,13.1-23.5,3.3,15.4-8.2,26.9-5.5,36.7,3.5-.8,22.9-2.2,27.9-11.3,4.3,9-.4,19.6-.4,23.4,12.2-5.6,23.1-10.6,24.8-12.9,1.4,15.2-11.2,23-14.5,31.6,7-1.2,20.3,1.6,26.2-4.8-2.8,20-16.8,20.5-23.8,26.4,9.8,2.6,14.6,8.9,19.7,18.3-12.2-.5-20.7,2.7-26.9,7.3,7.9,8.5,8.7,19.3,6.1,32.6-5.3-7.6-12.7-11.1-20.7-16.4-1.6,30.8-8.5,46-19.4,50.7,3-14,1.3-24.8-1.6-37.8-14,31.7-32.5,42.8-51.5,42.7,18.5-18.5,27-29.9,33.6-48.8,6.6-19.2,21.4-22.8,27.9-39.3,6.9-17.6-1.1-41.2-9.7-39.9-22.5,3.5-32.1,29.8-41.3,48.1-1.2,2.4-3.3,5.2-4.3,7.8-2.6-4.1-2.6-10.8-1.4-18.1,1.5-10,5.3-20.7,5.5-26.3,1.6,2.4,2.3,3.6,3.2,6.3,1.4-8.9,3.2-16,2.2-25.2-2.1-18.8-28,8.8-32.5,17-3.5,6.4-8.8,15.3-7.5,27.3,1.4,12.6-.4,20-3,29.4-3.9,14.4-9.3,23.6-10.1,45.7Z"
          fill="url(#linear-gradient-19)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_29">
        <path
          d="M356.9,450.5c13.6-.4,19.1,10,25.5,12.7-7.8-18.9-5.1-35.5-8.5-49.7,8.7,1.4,11.8,19,15,23.3-1-23.6,7.8-36.6,8-50.8,4.6,3.2,6.5,11.4,6.6,18.4,7.5-9.8,11.6-26.6,17-36.2,3.5,6.8,.9,17,4,21.9,3.4-4.6,5.7-16.4,13.7-24.2,1.1,4.5-2.2,16,2,19.4,1.9-6.6,6.4-12,8.9-12.8,.2,4.7,3.5,13.2,3.9,14,8.9-9.1,9.5-27.3,18.6-36.1,2.6,3.9-4.6,18.8-4.5,24.8,5.8-8.6,6.6-31.3,18.2-37.5,3.7,10.4-8.9,31.6-8.8,42.2,3.7-4.7,8.3-27.3,13.8-29,5.3,15.2-9.4,29.4-11.6,39.7,4.4-4.6,12-10.6,15-16.2,3,3.7,3.1,10.7,6.3,12.8,7.7-2.3,10.3-10.4,11.5-13.1,5.8,3.8,3.2,22.4,7.3,26.4,6.9-4.7,12.8-8.4,15-15.8,7.6,10.7-5.3,33-3.7,42.5,9-5.6,10.6-12.9,23.6-15.4-7.3,15.9-8.7,37.5-21.7,46.9,10.1,.3,23-6.4,30.2,3-15.9,9.7-31.5,25.9-47.6,30.9,7.9,9.8,25.7,13.4,31.4,28.9-13.8-3.2-29-2.1-52.8-3.7-17.9-1.2-21.8-10.7-11.4-24.2,14.2-18.2,23-32.2,22.3-39.5-2-21.5-10.9-46-20.4-43.3-15.8,4.5-24.3,25.6-32.3,42.8-4.3,9.2-10.6,20.7-15.5,26.1-4-12.1,2-32.9,.8-45.7,2.1,2.2,2.4,5.7,3.6,9.8,2.2-9.9,3.7-24.7,1.3-34.9-5-20.9-31.6,17.4-37.8,25.9-4.9,6.7-6.8,21.9-4.2,35.4,1.7,9.1,5.6,17.7,4.4,22.9-2.5,10.8-8.8,9.1-16.8,4.1-12.2-7.6-19.6-34.2-30.4-46.6Z"
          fill="url(#linear-gradient-20)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_30">
        <path
          d="M353.3,417.7c9.7,8.5,9.3,24.5,15.7,29.5-1.2-22.3,7-30.9,13.6-56.8,3.9,8.4,2.9,15.2,3.5,22.6,8.8-14.8,16.4-26.6,24.9-33.7,1.3,7.9-1.7,11.7,.3,20.5,8.5-10.4,14.6-12.3,21.1-17.4,.6,7.8-2,11,0,15.4,4.5-5,7.8-6.6,12.9-9.3-.3,6.7-.4,10.6,1.3,14.5,6.8-7,4.2-31.5,17-40.1,2.6,4.9-6.9,29-5.9,33,5.7-10,8.6-37.1,18.3-45.9,5,11.4-8.6,36.4-9.2,47.7,7.6-7.9,10.2-28.1,15.7-29.6,7.2,14-8.4,26-7.2,42.8,6.6-6,12.1-8.4,16.8-17.4,6.8,7.7,5.5,14.4,9.9,19.5,6.4-7,7-15.1,15.1-23,3,14.3,8.9,23,10.9,34.8,5.5-11.3,2.8-22.7,13.3-30.9,.5,14,10.8,22.9,9.8,45.1,10.7-6.4,14.6-14,25.5-17.5-7.6,17-2.7,36.9-16.2,49.4,15.1-3.4,23.7-13.9,39.7-15-16.4,21.8-39.1,56.2-67.1,62.8-10.6,2.5-23.1,1.6-18.4-10.5,7.2-18.6,11.4-37.7,6.7-46.5-12.6-23.6-27.1-27.3-38.4-25.2-19.5,3.6-29.2,28.6-47.2,63.6-3,5.9-4.4,6.7-7.2,12.8-4.9-12.1,.4-26.2,2.7-39.9,.6-3.2,.8-5.8,0-9,2,2.1,2.6,3.4,3.6,7.6,1.5-11.9,3.1-29.7-.8-41.3-3.9-11.7-28.3-7.9-36.5,1.3-10.7,12-6.1,30.5-11,45.3-6.2,18.4-17.7,7.8-25.5-10.5-5.4-12.6-5.7-32.7-7.7-48.5Z"
          fill="url(#linear-gradient-21)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_31">
        <path
          d="M333.4,438c.5,7.8,3.1,14.3,6.1,17.4-.4-24.5,4.4-22.1,13.4-36.6,4.1,7.3-.8,7.9,1.6,17.8,8.6-15.2,16.7-20.4,23.3-27.8,2.1,2.8,2.7,3.6,3.7,5.8,7.7-4.9,18-18.4,24-18.1,10.7,.5,8.5,16.1,19.3,19.6-1.6,4.6,1.4,13.7,10.1,16,9-7.6,4.1-38.9,17.1-48.5,5.3,9.5-4.9,35.6-4,40,6.7-10.7,10.3-46.4,21.2-55.7,9.1,14.8-8.7,43-11.4,57,8.6-8.4,10.9-30.4,18.1-33.4,7.4,15.6-7.8,24.9-7.2,43.3,9.1-.9,12.1-5.2,17.6-14.9,2.5,3.6,8.3,.6,12.2,8.3,5.6-5.6,8.9-8.7,15.3-11.1,2.5,7.8,6.1,3.7,9.6,13.6,7.4-6.1,11-6.4,16.8-8.4,0,15.4,13.8,10.1,11.7,34.5,9.1-3.3,7.6-5.3,17.2-15.1,1.4,13.3,4.5,20.2,1.3,40.3,14.3-5.9,9.3-24.3,21.3-34.6,4.6,25.8-7.3,53.6-21.3,68.6-14.3,15.4-30.6,17.6-32,3.4-2.2-22.6-8.6-40.7-17.5-46.9-17.2-12-37.6-5.1-50.7,1.5-19.4,9.7-27.9,22.5-42,49.7-4.4,8.5-9.3,20.1-10.2,28.4-9.4-10.5-6.8-28.7-3.6-43.6,.7-3.5,.7-7.2,.1-11.7,3.1,2.5,5,6,4.5,12.7,4.8-10.5,5-25.5,1.1-38.4-3.3-11-18.5-18.9-30.4-17.3-11.5,1.6-32.3,18.2-41.4,25.6-9.6,7.7-15.5,3.9-17.6-2.7-4.8-15-1.9-29.9,2.6-38.8Z"
          fill="url(#linear-gradient-22)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_32">
        <path
          d="M296.2,424.4c2.5,7.7,15.9,22,22.6,24.7-2.4-10.1,0-22.6,2.8-29.5,4.1,7.3,8.2,18.1,14,23.7,4.7-12.4,7.6-18.7,14.2-26.2,.3,6.3,5.8,14.3,6.8,16.5,9.4-5.4,16.6-18.1,26.7-18.5,.5,6.2,.9,19.2,4.1,21.7,7.6-2.6,16.4-5.7,22.9-3.6-1.6,4.6,6.3,17,13.7,22,9-7.6,3.7-36.1,16.8-45.8,5.3,9.5-4.9,35.6-4,40,6.7-10.7,11.9-48.1,22.8-57.5,9.1,14.8-10.3,44.8-13,58.8,8.6-8.4,14.5-34.6,21.6-37.5,7.4,15.6-11.4,29-10.8,47.4,9.1-.9,18.3-7.1,23.8-16.8,2.7,.7,4.8,1.8,7.7,6.3,5.6-5.6,14.4-9.5,20.7-14.7,3,4.9,.6,11.3,1.9,17.2,7.4-6.1,20.7-12.7,26.4-19.9,4.2,5.9-3,22.9-3.4,26.6,9.1-3.3,24.8-14,34.5-23.8,1.5,13.4,.7,13.2-3.2,25.9,14.3-7.8,23.8-25.6,35.7-36,4.6,25.8-11.5,51.5-25.4,66.6-14.3,15.4-21.8,10.3-35.8,7.1-35.5-8-62.1-7.9-77.6-3-21,6.7-36.3,35.4-45.7,53.5-4.4,8.5-8.6,19.7-9.5,28.1-9.4-10.5-7.5-28.4-4.3-43.3,.7-3.5,.7-7.2,.1-11.7,3.1,2.5,5,6,4.5,12.7,4.8-10.5,4-25.3,1.1-38.4-5.4-24.6-45.7-20.9-78.5-14.9-12.1,2.2-18-.4-22.2-5.8-10.6-13.7-17.4-39.2-12-52.1Z"
          fill="url(#linear-gradient-23)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_33">
        <path
          d="M417.1,468.6c4.5-11.3,3.1-31.8,14.6-39.7,5.1,10.1-5.8,35.4-5,40,7.4-10.9,15.7-50.7,27.5-59.9,8.9,15.8-13.9,47.2-17.3,61.7,9.3-8.4,17.9-36.9,25.5-39.6,7.1,16.5-14.5,31.2-14.6,50.3,8.5-4.5,18-22.1,20.7-34.7,2.8,0,5.1,.6,9.1,4.9,3.9-7.9,14.3-18.8,19-26.5,4.3,4.7,.6,16.6,3.5,22.8,5.5-9,18-23.8,21.6-33.6,5.6,5.4,1.7,30.3,2.4,34.6,7.8-6.3,22-30.3,28.7-44.2,5.1,14.7,2.4,22.2,1.9,37.7,11.7-12.8,16-35.7,24.7-50.8,11.3,27.9,2.8,61.5-6.6,82.5-9.6,21.5-18.2,17.8-32.5,18.3-36.3,1.1-60.7,7.1-76.6,12.6-27.9,9.6-42.6,47.4-56.1,71.1-4.9,8.6-10,18.6-11.3,27.2-9.4-11.3-6.3-28.2-2.5-43.6,.9-3.6,1-7.5,.6-12.1,3.2,2.8,5,6.5,4.2,13.4,6.4-12.7-.3-57.6-13.5-69-15.7-13.4-38.9-20-60.2-24.7-12-2.7-16.5-8.2-18.5-16.2-5-20.2-3.7-62.6,9.6-69.6-.4,9.8,4.6,37.1,9.9,43.1,1.3-12.5,7.6-32.8,15.3-37.6,1.3,10.1-1.2,29.3,2.2,38.2,8.7-12.1,16-23.3,24.7-28.9-2,7.3-2.1,24-1.9,27,10.6-2.1,21.7-13.5,31.2-9.5-1.7,7.3-5.9,22.2-3.8,26.5,7.9,.3,15.1-4.8,20.8-.6-2.4,3.4-.2,18.1,3.2,28.9Z"
          fill="url(#linear-gradient-24)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_34">
        <path
          d="M413.5,495.4c4.5-11.3,3.4-38.1,13.9-47.8,5.1,10.1-4.7,41.8-3.9,46.4,7.4-10.9,11.6-54.8,24.4-63.9,8.9,15.8-11.2,52.6-14.6,67.1,9.3-8.4,13.1-38.7,20.7-41.4,5.2,15.8-7.9,30.3-11.3,48.5,10.1-8.4,6.8-19.8,14.4-28.2,2,.2,2.9,4.9,5.2,4.7,5.6-7.8,10-21.3,11.1-26.3,2.5,1.6,3.5,3.6,4.4,7.7,3.9-7.9,11.1-15.5,15.9-24.7,4.3,4.7-1,13.2,1.8,19.5,5.5-9,7.8-27.5,11.3-37.3,7,9.2,7.6,22.2,8.9,31.9,7.8-6.3,3.2-35.5,9.8-49.4,5.1,14.7,9.4,10.5,13.7,28.4,5-12.6-2.6-31.4,6.1-46.5,7.9,19.4,21.3,39.8,13.6,66.2-3.5,12.2-16,31.2-27.6,42.1-26.6,24.8-62.6,27.7-78.5,33.2-27.9,9.6-40.8,49.2-54.2,73-4.9,8.6-10.6,18.3-13.3,26.7-9.4-11.3-4.4-27.7-.5-43.1,.9-3.6,1-7.5,.6-12.1,3.2,2.8,5,6.5,4.2,13.4,4.5-24.4-4.5-55.4-17.7-66.7-15.7-13.4-25.7-25.3-50.3-43.4-17.3-12.7-27.9-19.2-27.1-48.4,.6-21.2,3.3-45.8,1.2-57.8,12.5,9,11.6,32.1,16.8,38.1,1.3-12.5,3.4-17.3,5-27.7,9.7,12.2,7.8,33.2,11.1,42.1,8.7-12.1,6.8-16,9-23.3,6.8,6.6,4.7,26.6,4.8,29.6,11-4.9,10.9-7,15.3-12.8,9.1,9,8.5,19.7,10.3,28.3,7.9,.3,8.5,1,12.3-2.9,5.6,5.6,8.3,18.6,13.8,30.6,2.9,.4,9.9-4,12.2-5,5.1,5.2-.7,19.6,7.2,31Z"
          fill="url(#linear-gradient-25)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_35">
        <path
          d="M401.6,530.7c5.6-11.1,5.8-35.6,17.4-44.6,4.3,10.8-6.2,38.6-5.8,43.5,8.6-10.5,11-52,25-60.1,7.6,17-8.1,45.2-12.9,59.7,10.3-7.7,12.1-32.4,20.1-34.4,3.8,16.7-7.3,26.4-12.6,44.7,11.2-7.6,11.2-19.6,17.1-26.9,1.4,1.6,1.4,4,3.1,7.9,6.5-7.4,14.2-24.8,15.8-29.8,2.4,1.8,1.2,7.9,1.8,12.2,4.8-7.7,16-20.6,21.8-29.6,3.9,5.2-5.5,19.3-3.2,25.9,6.5-8.7,17.3-34.1,21.9-43.8,6.3,10-1,30.2-.6,40.3,8.6-5.7,13.4-43.1,21.4-56.7,3.8,15.6,1.9,18.8,4.5,37.5,12.3-16.2,14.7-52.4,24.6-68.1,6.2,20.6,4.1,63.8-6.3,90.1-4.8,12.1-19.3,30.4-32.2,40.4-29.5,22.8-66.7,22.5-83.5,26.6-29.4,7.2-46,52.9-62,76-5.8,8.4-16.7,20.3-20.2,28.7-8.5-12.4,2.4-28,7.6-46.8,1-3.7,1.6-10.9,1.6-15.6,3,3.1,4,10.3,3.2,17.4,6.9-24.5,.3-63.4-12.2-76.3-14.8-15.2-23.9-28.3-47.3-49.1-16.5-14.7-22.1-24.3-26.2-54-3.2-23.1-6.6-43.6-15.1-62.4,18.9,8.9,32.2,43.1,37.1,49.7,2.6-12.7,.3-25.8-1.8-40.1,14.3,10,15.7,42.9,18.3,52.4,6.8-14,6.4-22.1,7.4-32.7,8.8,10.2,6.4,33.6,6.1,40.5,10.7-9.6,12.9-15.9,14.6-23.8,8.5,10.1,9.1,33.1,10.1,42.1,8.5-3.6,10.7-4.8,14.5-11.1,5.2,6.2,5,29.1,9.6,42,5.3-4.8,10.4-10,12.9-14.4,4.7,5.8-2.5,30.5,4.4,42.9h0Zm-38.2,107.8c-1,2-1.9,3.8-2.6,5.9,1.8-.7,2.7-1.2,2.6-5.9Z"
          fill="url(#linear-gradient-26)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_36">
        <path
          d="M391.3,555.5c7.5-10.4,6.2-33.9,19.5-41.2,2.7,11.6-3.6,22.5-3.9,27.5,10.3-9.3,9.7-40.2,25.2-46.3,5.1,18.4-4,35.2-10.4,49.5,8.3-9.4,8.8-22.8,15.7-24.4,4,14.1-6.9,29.2-15,47.1,12.5-6,13.2-14.9,20.4-21.4,.5,1.2,1,1.6,1.1,3.2,5.4-5.4,8.9-13.5,13.7-16.3,.2,1.9,.8,2.6,.7,4.6,8.7-5.9,13-13.3,22.2-15.8-1.3,4.6-1,6.8-2.3,10.5,7.9-7.9,23.3-24.1,35.7-27.1-3.6,6.1-4.2,10.7-5.4,21,9.3-5.7,22-23.7,34-29.5-1.1,8,.4,12.3-1.6,23.5,15-14.6,29.8-46.9,42.4-61.4,3.6,21.4,1.9,42.2-31.2,88.5-22.4,31.4-99.8,33.9-123.2,49.3-26,17.1-52,50.2-71.9,71.2-7.2,7.6-17.5,14.7-21.8,26.7-7.3-11.7-.4-23.8,7.8-38.9,5.4-10,6.1-15.4,6.4-25.4,3.3,3.6,4.2,12.8,2.3,19.9,12-22.2,11.6-55.4,5.9-73-7.2-22.1-36.9-55.9-54.9-77.5-14.5-17.5-15.3-14-30.8-40.7-12.8-22.1-12.1-41.9-13.7-62,17.9,12,29.9,33.5,33.8,41,1.2-3.7-2.5-7.8-3.2-16.1,13,12.4,20.1,20,23.7,31.5,1.2-5.3-2.3-10.7,.1-17.6,4,11.2,13.5,20.3,15.2,32.3,5.2-3.8,3.8-7.6,7.8-15,4.6,12.8,11.5,27.6,11.2,36.9,4.2-1.4,6.9-4.4,10.1-10,1.6,11,8.3,24.8,10.9,38.6,3.5-1.8,5.1-3.2,8-8,6.7,12.8,6.5,33.8,15.5,44.9h0Zm-48,107.5c-1.2,1.9-2.3,3.6-3.2,5.6,2.5-.2,3.1-.8,3.2-5.6Z"
          fill="url(#linear-gradient-27)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_37">
        <path
          d="M373,594.8c11.2-7.3,11.1-35.7,24.9-42.3,2.1,11.9-5,21.8-5.6,26.9,11-8.8,10.8-41,29.4-46.5,4.5,18.9-10.8,35.7-15,51.5,8.9-9.1,10.7-22.7,17.8-23.9,3.2,14.5-9.8,34.4-20.8,48.6,13-5.3,15.9-19.2,23.5-25.4-.3,1.3,0,2-.3,2.9,5.8-5.1,9.2-9.1,14.2-11.7-1,1.7-1.2,2.6-1.4,4.5,8.5-3.9,15.9-10.1,25.9-10.6-3.2,2.2-4.6,4.5-6,7.4,10.8-3.9,21.5-15.7,37.8-16.8-4.3,3.3-5.4,4.9-8.2,10,11.7-5.2,35.5-18.7,51.8-17.8-4.4,5.1-4.6,6.5-6.4,10.8,16.9-10.6,41.2-16.8,60.6-11.7-26.5,9.4-46.7,24.3-74,43.8-31.7,22.7-65.4,13.4-116.6,41.4-27.7,15.1-56,56.3-80.6,68.8-9.5,4.8-21.7,4.8-28.1,16.8-6.6-12.3,5.9-17.9,15-32.7,6.1-9.8,7.1-15.2,7.9-25.3,3.1,3.9,3.5,13.2,1.1,20.3,13.5-21.7,18.4-53.8,13.6-72-6-22.8-34-58.7-50.9-81.5-13.7-18.5-14.6-15-28.7-43-11.6-23.1-12.7-33.3-34.6-60.9,18.8,.2,46.6,33.4,50.1,41.2,0-4.2-3.6-17.7-11.1-31.1,15.6,6.3,33.5,39.8,36.5,51.7,1.5-5.3-.6-13.6-2.2-24,9,10.3,14.7,24.2,17.2,35.6,1.8-3.6,2-9,1.4-15,6.8,8.4,12.7,25.1,14,36,2.5-1.5,3.5-3.7,5.1-7.9,6.7,12.4,8.4,26,15.6,40.1,.8-1.7,.3-3.8,2-7.2,9.3,13.6,12.6,37.1,24.9,49h0Zm-60.6,98.6c-1.4,2.1-3,4.1-4.9,5.9,3.3,.7,4-.2,4.9-5.9Z"
          fill="url(#linear-gradient-28)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_38">
        <path
          d="M353.2,639.4c18.8-8.2,13.3-36.5,28-41.9,.9,12.3-5.7,23-6.8,28,12-7.9,14-37.5,32-45,2.8,19.7-14.3,33-17.9,51.4,9.9-8.3,12-24.1,19.3-24.7,2.1,21.6-18.2,36.1-24.8,49.4,13.3-4,15.5-23,40.6-32.9-.5,1-.8,2.1-1.1,3.2,9-3.2,14.6-8.4,24.1-8.5-3.5,1.9-3.7,2.9-5.4,5.6,11.4-2.9,22.5-12.6,39.2-12.1-3.2,3.6-3.3,5.6-5.5,9.2,12.4-4.1,37.5-11.4,54.3-7.6-7.8,2.8-13,6.6-15.2,10.7,18.9,1.4,40.5,6.9,51,24.4-29.5,.3-47.2-8-77.6-9.6-23.4-1.2-43.1,10.2-61.3,23.5-8.8,6.4-41.6,20.6-75.1,33.2-17.1,6.4-29.3,12.3-41.3,16.5-11.3,4-22.4,6.6-37.5,7.1-10.9,.3-22.1-5.3-33.3,2.8-.6-14.2,10.4-14.4,25.4-23.8,9.9-6.3,13.3-10.8,18.6-19.6,.7,4.9-.2,10.5-3.9,16.6,20.1-8.9,24.2-23.8,27.8-48.9,2.9-20.7-2.6-39.9-15-62.7-8.8-16.2-14.1-17.8-42.1-31.7-23.6-11.8-15.2-41.5-26.1-70.1,19.5,5.9,32.1,30.4,34.9,38.6,.4-4.3,1.5-20.4-4.9-34.7,15.3,8,27,31.7,28.8,44,2.3-4.8,3.5-8.3,2.3-18.1,9.3,8.9,14.5,25,16,36.9,2.2-3.5,4-5.4,4-11.5,6.1,9.2,8.6,20.2,8.8,31.4,2.7-1.2,5.3-3.9,7.1-9.7,5.6,13.3,5.1,26.9,11,41.9,1-1.7,.6-3.9,2.8-7.1,8.1,14.7,7.3,32.4,18.6,45.6h0Zm-84.7,62.8c-2.3,1.5-4.8,2.8-7.5,3.9,3.1,2.1,4.1,1.5,7.5-3.9Z"
          fill="url(#linear-gradient-29)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_39">
        <path
          d="M335,656.5c20.7-5,14.8-17.8,30.8-20.6-.9,8.9-3.5,11.1-6.3,13.4,2.8-.8,3.2-.9,6.1-3.9,6.3-6.5,9.7-16,21.9-22.4,1,20.5-8.2,21.1-15.7,35.4,11.7-6.7,14-16.8,21.6-16.1-1.8,22.5-21.1,27-30.9,37.3,100.9-23.8,142.2,1.5,179.5-7.2-7.7,11.6-23.3,16.2-43.5,18,3.4,2.6,8.4,4.6,15,5.7-16.6,6.9-43.6,4-57,2,2.9,3.3,3.3,5.3,7.3,8.4-17.1,3.5-30.2-4.4-42.4-5.3,2.2,2.5,2.6,3.4,6.6,4.8-9.8,1.7-21-4-30.8-5.6,.5,1.1,4,4,4.7,4.9-13.1-.9-33.4-11.4-57.4-5.5-4.8,1.3-9.6,2.8-14.3,4.6-3.4,1.3-6.7,2.7-9.8,4.3-2.3,1.2-4.4,4.9-5.3,7.6-1.2-.5-2.2-2.1-1.8-3.9-3.2,1.6-3.8,3.9-2.7,7.1-2.4-.3-3.9-1.5-5-3.1-4.3,2.2-8.9,4.2-14,5.9-15.6,5.2-35.2,5.5-50.1-1.7-6.1-3-11.8-7-16.4-11.5-6.1-5.9-12-12.2-22.6-12.3,8.3-12.2,20.6-7,39-5.5,12.2,1,17.8-.7,27.7-4.8-2.5,4.5-6.7,8.7-13.6,11.5,19.6,2.2,39.1-37.1,26.2-78.5-7.3-23.5-9.7-24.1-27.9-55.6-13.7-23.8-6.2-62.4,12.7-89.4,6.3,17.5-1.5,41.8-.1,50.8,3.1-5.3,15.1-19,18-33.6,6.9,15.4-2.3,34.5-2.6,47.5,3.2-4.5,7.5-7.9,12.7-17.5,7.9,10.8,.8,23.1,.2,35.5,2.9-3.2,9.3-3.2,12.7-8.8,4.5,10.5-1.5,18.1,0,28.8,3-.8,6-.7,11.1-3.8,2.1,16.1-1.7,23.6,1.6,40,1.4-1.5,3.3-5,7-7.9,4.2,17.4-1.2,35.5,8,51.2h0Zm-96.5,39.5c-2.9-.1-5.9-.5-8.8-1.1,1.3,4.1,2.7,3.8,8.8,1.1Z"
          fill="url(#linear-gradient-30)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_40">
        <path
          d="M305.3,664.6c22.2,.4,34.2-7.1,46.7-9.7-3.7,8-7.5,11.5-10.8,13.1,9.6-.2,24.6-10.8,31.8-16.4-5.5,18.1-8.6,16.2-19.3,26.2,.4-.1,.8-.2,1.2-.3,8.9-4.8,17.2-11.7,29.2-21,40.6-31.5,72.3-21.3,121-61.8-4.3,14.8-17.9,45.1-37.7,53.3,4.1,1.7,13.9,1.6,22.4-.9-14,18.1-40.1,18.8-54.2,21,3.8,2.7,11.9,6.2,19.3,7.6-16.1,9.1-47,3.8-59.5,6.6,3,2,14.8,4.5,19.4,5.1-9.3,4.9-35.7,1.4-46,2.7,.8,1,11.1,4,12.1,4.8-27.5,10.1-53.8,4.5-64.2,14.5-2.4,4.6-2.1,10.3-4.5,20.2-5.4,.8-22.8-2.5-31.5-1.2-6.2,.9-5.2,5.4-1.8,9.8-14.2-2.6-6-10.3-1.4-12.3,2.8-1.3,16-2.6,23.5-2.5-5.4-3.3-9.4-2.8-15.5-2.4-7.6,.6-15,1.1-22.5,1.1-28.9,.1-49.1-14-59-22.5-5.4-4.6-11.3-17.6-14.7-23.4-4.6-7.5-8.9-15.5-19.5-18.4,11.6-10.1,22.6-1.6,40.7,4.7,12,4.2,18.1,4,29.2,2.5-3.7,3.9-9.1,6.9-16.7,8,8.4,7.4,40.5,12.6,49.9,3-32.4-51.8-35.8-66.8-25.2-128.8,4.8-28.2,18-48.4,47.6-70.8-.2,22.2-19.9,37.1-21.8,59.3,4.5-4.5,27.1-24.6,36.1-36.2,4.7,21.6-20.3,41-24.1,54,9.8-10.3,18.9-10.8,27.6-19.9,1,16-18.9,31.3-22.8,43.6,7.8-7.2,22-9.3,26.9-14.2,1.9,13.7-18.9,28.3-20.2,39.6,6.4-3.1,14.8-6.5,21.4-10.8-2.1,16.8-17.3,22.4-18.2,39.9,3.9-3.8,13.2-7.4,19.4-11.8-.3,18.6-19.4,26.4-14.2,44.6h0Zm-36.3,75.2c-3-6.5,0-12,3.9-13.6-7.1,.8-17.3,10.1-3.9,13.6h0Zm-9.6-5.5c-.2-3.8,3.1-6.4,5.5-7-4.3-.7-11.8,2.9-5.5,7h0Zm-51.6-67.1c-3-1-6-2.2-8.8-3.8,.1,4.7,1.6,4.7,8.8,3.8Z"
          fill="url(#linear-gradient-31)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_41">
        <path
          d="M278.2,675.3c58.2-10.7,112.8-48,126.1-125.7,10.3,18.1,9.7,33.7,4.6,55.2,5-.6,10.4-2.8,18.7-9.5-2.8,29.7-16.2,36.7-30.2,40.6,3.7,4.7,13.1,7.4,22.4,8.3-14.2,12.8-24.1,16.4-40.1,15.3,.9,6.4,6.4,11.2,13.4,14.8-10.3,6.6-29.7,8.5-41,5.4,4,7.3,10.8,12.5,16.6,13.9-6.1,7-39.4,6.3-45,8.4,9,2,15.2,3.2,21.4,3.9-12.4,15.1-26.4,6.2-39.3,3.9-17.5,5.9-33.6,7.9-38.4,18.7l-.2-.6c-2.2,3.5-4.4,7.6-7.6,12.4-5.5-1.2-25-15.2-33.9-17.1-6.3-1.3-6.9,3.4-5.2,8.8-12.8-7.5-2.1-12,3-12.4,3.1-.2,21.2,5.4,28.8,9.2-1.9-5-14.3-11.6-24.1-15.4-37.4-14.5-58.7-48.6-51.9-88.6,1.1-6.2,1.4-17.7-6.1-22.1,18.9-2.9,18.5,7.4,29.5,23.4,7.5,10.9,12.6,15.9,23.6,19.3-5.5,1.7-10.5,2.1-17.7-.4,4.5,14,26.1,23.6,42.7,25.1-23.9-51.6-17.4-101.9,2.1-134.7,15-25.2,50-40.4,86.4-51.6-8.1,21.4-40.2,29.2-49.8,49.9,5.9-2.8,31.4-8.9,44.2-16.9-3.1,22.4-35.8,29.1-44,40.3,13.4-4.1,21.1-1,32.7-6.8-3.1,18.1-30,23.9-38.2,34.4,10.5-4,26.8-3.2,33.3-6.2-3,13.9-27.6,19.9-32.8,30.3,10.4-4.1,27.1-3.2,36-5.5-8,15.4-27,17.8-34.1,34.3,7.9-.7,10.4,1.2,20.6-1.2-6.3,16.4-24.1,21.9-26.8,38.8h0Zm-83.2-44.1c-1.7-2.9-3.3-5.9-4.5-9.1-3.1,3.8-1.9,4.9,4.5,9.1Z"
          fill="url(#linear-gradient-32)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_42">
        <path
          d="M255.4,671.7h0c-16.4-8.4-38.1-21.9-46.7-34.8-.4-3-.6-5.9-.7-8.7-3.6-1.2-7.2-3.4-10.5-7.2,0,18.7,19.3,34.6,35,41.7l23,9h0Zm-80.1,46.5c-9.4-8.3-.7-12.6,6.5-12,2.2-.5,4.8-.3,6.6,.2,3.1,.8,20.8,10.6,28.4,16.4-.2-5.6-10.1-16.4-18.3-23.6-31.4-27.2-40.7-68.6-20.9-106.2,3.1-5.9,7.2-17.2,1.5-24.2,19.3,3.6,15.5,13.6,20.8,33.4,3.6,13.4,6.9,20.2,16.4,27.4-2.3-3.3-7-11.6-8.2-14.4,.3-3.5,.7-7,1.3-10.7,4.4-29,28.9-60.5,56-89.4,.2,22.1-24.3,45.8-25.6,68.3,4.1-5.5,17.9-20.5,26.1-34.1,4.7,20.4-15.1,39.7-18.3,53.4,9.9-10.6,11.7-9.4,19.6-20.4,3.2,16.7-11.7,33.2-15.1,46.3,7.5-8.9,16.3-14.6,20.8-20.6,2,13.2-11.5,28.9-12.5,40.3,7.4-9,13.7-12.8,20.5-19.6-1.7,17.1-8.9,25-9.5,42.5,2.7-1.4,4.7-4,8.9-8.5,.2,3.7-.2,7.5-1,11.3,46.9,1.4,94.8-17.3,130.7-69.7,1.5,21.9-10.9,47.6-27.2,63.8,4.9,1.7,15.7,1.1,28.3-5.8-9.6,24.3-38,30.6-52.7,28,1.3,6.1,13.2,10.1,23.9,11.2-11.5,8.1-31.6,8-51.9,.6,2.6,6.9,6.6,11.4,13.9,16.2-7.9,1-20.2-.2-30.4-3.3-3.8,1.3-7.1,1.7-10.8,1.9,3,3.7,7.3,6.5,10.1,8.8-2.8,1.3-8.1,.7-14.2-.8-10.9,0-19.6-4.6-32.3-6.2-6.9,.5-11.2,1.7-17.4,1.2-11.9-.9-16.4,6.7-29.1,16.6-4.9-3-22.8-21.1-30.7-26-5.6-3.5-7.8,1-7.9,7-2.5-2.9-3.6-5.2-3.8-6.9-1.3,1.8-2.2,4.3-2.3,7.4h0Zm-6.1-8.3c1.4-3.7,5.7-4.8,8.3-4.4-4-2.6-12.7-2.5-8.3,4.4h0Zm11-7.5c3.9-5.4,18,3.7,20,5.4-8.9-11.3-20.3-13.5-20-5.4h0Zm-1.4-3.8c.7-2.4,3.9-3.1,5.9-2.8-3.2-1.7-8.1-1.1-5.9,2.8h0Zm15.2-89.2c-.9-3.9-1.5-7.8-1.7-11.9-4.9,3.2-4,4.8,1.7,11.9Z"
          fill="url(#linear-gradient-33)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_43">
        <path
          d="M248.3,660.1c-16.9-12.6-42.2-33.7-48.1-53.2,4.8,2.4,9.1,2.8,13.5,2.5-10.1-19.2-7.5-30-7.4-46.4,0-5.2,1.1-9.6,4.4-13.7,17.4-22.1,46.6-28.3,73.4-39.8-4.5,21.7-33.7,29.1-40.2,50.4,5.5-4.3,19.2-6.5,30.8-12.4-4.2,18.5-21.2,25.9-28,37.5,11.1-3,16.5-4.9,25.6-11.9-.3,17.1-18.4,30-24.5,42.1,9.2-7.2,19-10.8,24.6-15.8-.8,13.3-20.7,28.3-24.1,39.3,7.3-4.6,11.6-4.8,17.4-7.4-6.6,17.3-11,18.6-15.2,29.3,19.8,7.2,79.4,19.9,130.9-30.1-3.1,21.6-12.9,35.9-30.9,47.6,4.5,2.7,16.2,5.8,30.9,2.3-13.2,20.8-40.9,23-54.8,17.4,1.1,5.9,6.1,13,15,16-11.6,3.3-21.3,.7-34.8-9.3,1.1,7.3,2.1,12.6,8.3,17.2-9,.7-19.7-3.3-30-11.5-2.6,.2-3.5,0-5.7-.4,2.2,4.3,17.5,9.2,21.1,11.3-5.1,2.3-21.7,1-27.3-1.6-10.2-2.2-17.6-8.2-28.9-12.4,.5,1.7,1.2,3.3,2.3,4.8-2.5-.3-4.5-.9-6.3-1.6-6.2-2.7-9.5-3-15.9-.4-5.8,2.4-13.3,6.2-22.6,12.3-4.2-4-18.1-20.8-24.8-27.2-4.8-4.6-7.8-.6-9.2,5.2-1.8-3.4-2.4-5.8-2.3-7.6-1.7,1.5-3.1,3.7-3.8,6.7-7.5-10.1,1.9-12.5,8.8-10.4,2.3,0,4.8,.8,6.4,1.6,2.9,1.5,18.6,10.3,24.7,17.5,1-5.5-14.6-26.3-21.2-35-25-33.2-25.4-75.6,1.9-108.2,4.3-5.1,11.4-14.4,10.8-24.1,8.6,6,9.6,13.7,9.5,24.9,0,7-.5,17.2,.2,23.6,.6,5.2,.9,10.8,3.4,14.9-5-.8-8.2-3.4-10.5-7.7-4.3,27.4,32,55.7,52.2,63.9h0Zm-91.3,28.7c2.6-2.9,7.3-2.6,9.7-1.6-3.3-3.3-12.6-6-9.7,1.6h0Zm7.9-11.6c4.9-4.4,20.9,12.4,22.5,14.4-8.4-17.3-21.2-22.5-22.5-14.4h0Zm-1.5-3.7c1.2-2.2,5.3-2.6,7.2-1.9-3.2-2.9-9.5-3.1-7.2,1.9h0Zm31.8-89.4c0-4,.2-7.9,.9-12-5.5,2.1-4.9,3.9-.9,12Z"
          fill="url(#linear-gradient-34)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_44">
        <path
          d="M286.7,699c-1.4,.2-2.7,.3-3.8,.3,.7,4.3,2.1,8.1,4.4,12.1,7,2.2,14.8,3.1,19.5,5.4-18.7,8.4-31.5,1.7-46.9,.7,6.8,5,10,3.3,15.9,8.5-32.2,3.1-47.3-18-58-19.6-3.7-.6-7.6-.7-24.4,6.2-4.1-4.5-17.1-24.7-26.9-33.6-5.1-4.7-8.1-1.2-10,4.8-1.6-3.6-2.1-6.2-1.9-8-1.6,1.7-2.8,4.2-3,7.5-11.1-10.9,3.4-14.1,11.4-10.6h0c1.4,.4,2.8,1,3.8,1.6,2.9,1.7,21.4,15.2,27.3,23.2,1.4-5.7-8.8-18-15.1-27.6-24-36.5-21.5-80.9,9.3-113.2,4.8-5,14.9-16.3,19.4-28.4,6.8,11.7,4.6,18,2.4,27.8-2,9.2-6.3,32.4-4.2,40.7-3.3-1.3-4.9-3-6.1-4.8-4.7,16.2,6,33.3,17.8,45.3,44.6,29.9,101.2,35.5,154.5-18-3.9,22.6-20.4,44.9-37.9,56.6,3.7,3.1,12.9,5.6,25.1,2.3-13.8,21.8-39.1,20-50.8,13.2-.3,6.5,8.6,14,17.4,18.2-10.5,4.4-25.3,0-39.3-10.7h0Zm-87.4-121.2c.3-4.5,.9-9,2-13.5-6.4,1.8-5.9,3.9-2,13.5h0Zm-46.4,81.4c7.5-4.5,23.3,17.6,24.8,20.6-10.8-24.3-21.1-30.7-24.8-20.6h0Zm-2.9-3.2c.8-3.3,5.1-4.5,7.9-4.2-4.6-2.1-13.7-1.6-7.9,4.2h0Zm-5.4,16.1c2.2-3.6,6.9-3.8,9.4-2.8-3.5-3.4-12.6-5.2-9.4,2.8h0Zm12.7-51.1c-1.6-22.7,9.2-46.4,23.4-62.5,7.2-8.2,16.1-16.4,21.6-25.8-9.6,1.5-18.1,4.6-24.4,10.2-18.1,16.2-36.3,39.4-20.7,78.1h0Zm57.1-89.3c7.3,20.4-7,41.2-4.1,69.2,10.7-7.1,34.3-6.6,40.7-18.2-12.4-1.6-17.5,.8-31.3,.8,10-9.3,40.5-10.2,52-22-13.8-2.6-40.2-1.1-46.5-.5,13.5-9.8,40.8-8.6,57.2-18.6-20.6-5.4-46.2-11.2-68.2-10.8h0Zm-3.9,70.1c0,.4,.1,.9,.2,1.3-1.9,.1-5.4-.6-7.5-1.8,1.1,5.1,3.2,10.2,6,15.1,8.1-3.1,20.1-2.7,24.8-10.3-7.3-1-13.4-4.5-23.5-4.3h0Zm.4,17.5c2.1,3.2,4.4,6.3,7.1,9.1,2-1.2,4.2-2.7,7-5-7-.2-8.7-3-14-4.1Z"
          fill="url(#linear-gradient-35)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_45">
        <path
          d="M265.6,692c-2.5,3.9-2.4,9.1-1.1,14.5,1.9,2.3,3.2,4.9,7.5,7.9-11.1-.7-22.6-9.1-37.6-12.4,5.8,13.8,19.7,21,24.8,27.2-20.1,0-35.3-10.1-44.8-19.9-.2,3.9,3.2,10,4.9,15.7-5.7-1.1-13.9-12.7-16-18.5-2.5-6.8-4.3-14.1-13.7-13.7-3.6,.2-4.9,.6-14.7,3.1-3.4-5.2-13.3-27.8-21.7-38.3-3.7-4.6-9-5.4-13.5-2.9-.5-4,.4-5.9,2.1-6.6-1.8-.1-3.5,.1-4.5,.9,0-8.8,13.2-2,15.2,2.6-.3-.3-.8-.6-1.4-1,1.2,.7,2.3,1.5,3.2,2.3,2.1,1.8,16,16.3,22.6,25.3,1.5,2,4.1,1.1,10.6-7.3-5.2,1.6-6.4,2.4-9.7,2.6-1.8-5.2-5.6-12.6-8.7-18.8h0c-1.5-3.2-16.9-25.9-24.8-22.4,1.9-3.1,4.2-4.4,6.9-3.6-2.3-1.4-4.9-2.3-7-1.8,3.6-8.3,12.5,2.4,18.7,12.8-2.7-8.1-4.8-12.2-8-19.9-11.6-28.1-.7-86.8,5.2-101,7.8-18.8,36.3-52.3,58.9-59-1.3,6.6-28.8,47.3-31.8,56.3,7-1.5,25.6-17.7,32.9-25.2-.2,7-3.3,13.3-9.7,23.1-6.9,8.7-16.9,15.8-25.2,23-5.4,4.7-10.5,10.2-15.1,16.5-4.6,6.3-11.1,25.6-11.4,42.4h0c3.1-22.6,14.6-43.6,34.3-59.3,5.5-4.4,17.2-14.9,28.6-25.3,2.4,5.2-3.3,18.4-6.9,28.1-3.3,9.1-12.8,28.4-14.1,37.1-.7-.6-.9-1-1.4-2.1-7,15.8,4.8,33.1,14.7,47.1,39.7,37.2,95.8,52.7,156.8,15.9-7.2,22.4-35.7,36.7-59.6,44.1,3.2,3.7,21.6,9.4,34.2,7.9-16.9,20-42.2,11.7-52.8,3-1.3,6.6,5.4,16.7,12,23.2-11,2.9-26.4-10.4-38.7-23.5h0Zm-61.5-108.3c-1.2-.3-2.6-1.2-3.8-2.2,0,6.1,2.9,14,5.7,19.1,6.5-5.6,7.9-14.1,12.8-21.7-4.9,.4-6.8,2.1-11.6,3.5,7.1-10.6,13.5-22.4,17-38.6-6.7,6.9-11.8,9.3-13.5,12.6-5.2,10.2-7.9,19.1-6.6,27.2Z"
          fill="url(#linear-gradient-36)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_46">
        <path
          d="M141.3,516.6c2.8-28.9,41.4-66.3,73.7-73.1-7.3,17.1-34.9,35-39.6,53.2,5.9-1.8,17.4-13.9,29.4-25.1-4.9,23.9-21.4,34.6-30.1,50.2,5.3-1.1,16.1-10.1,26.2-21.9-.5,5.1-2,10.1-4.2,14.8-4.6,2.3-9.9,5.5-14.2,5.8,.3,2.3,1.4,4.7,2.3,6.7-10.4,11.6-17.5,29.4-19.7,43.3h0c5.6-14.5,13.6-29.6,24.5-44.4-.6-.5-1.5-1.2-1.8-2.5,6.9-2.4,13.6-7.8,17.1-10.1,6.3-4.1,25.6-22,40.1-18.5-10.6,9.7-18.5,24.6-26.6,38.6-15.2,26.3-25.7,43.9-3.4,80.6,34.8,41.5,84.1,59.3,162.6,33.1-10.2,23.3-39.9,31-71.8,34.8,2.9,4.4,28.9,12.8,42.3,12.5-20.1,19.8-45.4,11.1-55.5,.7-2.1,7,5,17.4,12.8,24.3-13.1,2.2-28.8-8.6-40.7-27-1.5,8.5-.8,15.2,2.4,23.9-3.7-1.7-8-4.9-12.3-8.8-9.2-.8-14.1-3.3-17-6.3,0,.5,0,1,0,1.5,.5,1.7,.9,3.2,1.3,4.6-.3,0-.5,0-.8-.1,3.3,13.4,22.2,17.9,29.6,24.5-21.4,4-32.7-6.6-48.1-11.7,5.5,7.2,9.3,6.3,13.9,13.4-26.8-4.2-52.8-36.2-67.2-66.2-5.5,1.3-12.4,2.3-16.2,2.7,.3-3.8-4.8-42.6-14.7-38.9,4.9-7.6,10.8-3.9,14.6,4.8,1.7-.8,4.2,.4,6,2.4-.5-2.7-.9-5.5-1.1-8.5-13.8-27.7-18.9-60.2-13.7-113.5h0Zm11.3,127.3c1.4-.4,3-.4,4.7,0-.3-1-.5-2.1-.8-3.2-1.5-.8-3.3-1.2-5.1-.9,.4,1.3,.8,2.7,1.2,4.2h0Zm11.7,3.8c-1.8-.6-3.8-.6-5.9,0h0s0,0,0,0c-1.6,.5-3.2,1.4-4.7,2.8,.4,3,.5,6.2,.4,9.4,2.9-.4,5.5-1.4,7.7-2.4,.2,0,.4,.1,.5,.2h0c.2,0,.3,.1,.5,.2h0c.2,0,.3,.2,.5,.2h0c.2,0,.3,.2,.5,.2h0c.2,0,.3,.2,.5,.3h0s0,0,0,0c.2,0,.3,.2,.5,.3h0c.2,.1,.3,.2,.5,.3h0c.1,.1,.3,.2,.4,.3h0c.1,.1,.3,.2,.4,.3h0c.1,.1,.3,.2,.4,.3h0c.1,.2,.3,.3,.4,.4h0c.1,.2,.3,.3,.4,.4h.1c.1,.2,.2,.3,.4,.4h.1c.1,.2,.2,.3,.3,.4h.1c.1,.2,.2,.3,.3,.4h.1c0,.2,.2,.3,.3,.4l.2,.2,.3,.3,.2,.2c0,.1,.2,.2,.3,.3h.1c0,.1,.3,.4,.3,.4l.2,.2,.2,.2,.2,.2h0s.1,.2,.1,.2h0s.2,.3,.2,.3h0s.1,.2,.1,.2h0s.2,.3,.2,.3h0c0,0,.1,.3,.1,.3,5.5,7,9.9,16.4,15.6,22.5,2.8-3.5,5.9-10.3,5-16.5-1.6,1.9-3.9,2.9-6.5,3.4-9-10.2-10.9-22.9-22.3-27.5h0Zm-28-25.6c2.6-2.8,9.4,0,12.4,4.1-1.7-8.3-9.5-11.8-12.4-4.1h0Zm62.4-98.7c4.4,.2,6.4-1.7,8.8-8.1-2.3,2.2-6.5,5.8-8.8,8.1Z"
          fill="url(#linear-gradient-37)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_47">
        <path
          d="M240.2,364.9c-10.5,18.7-44.1,54.6-52.1,75,6.5-1.1,26.2-24,45-36.4-6.6,30.9-30.3,48.8-42.1,65.6,5.8-.4,20.3-12,32.8-25.2-.6,8.9-8.2,26.8-13,31.9-6.8,4.8-16.5,4.3-24,2-.6,3.7,2.6,13.3,6.4,16.1-5.7,3.6-13.6,13.8-17.6,23.6h0c7.6-9.3,16.8-17.5,27.6-24.8-4.4-.4-10.6-5.4-12.1-9.5,10.5,4.4,24.5-2.3,30.1-5,17.2-8.6,36.6-19.7,48-.6-12.7-3.6-25,12.7-35.8,27.7-12.1,16.7-21.9,30.1-24.2,47.9,53.2,65.5,127.4,96,199.8,28.3-4.8,33.6-29.5,56.6-67.3,63.4,4.2,5.2,34,10.6,48.1,7.1-16.2,31.2-45.3,25.4-58.8,13.8-.5,9.9,9.8,22.2,19.8,29.6-13.4,6-32.7-4.9-50.1-26.9,.6,11.8,3,20.6,8.7,31.6-12.5-4.2-41.4-22.4-47.8-39.4-.2,14.7,8.1,25.8,15.7,33.8-23-1.9-44.2-31.9-52-51.2-.4-1-.8-2-1.2-3-11.6,29,7.3,46.9,16.8,68.6-10.9-2.3-27.5-17.2-30.2-21.4-2.2,14.4,12.2,22.1,12,32.1-22.2-11.1-27.5-23.8-40.6-34.3,2.7,10.3,2.2,12.5,4.7,20.9-27.8-17.8-42.8-56.1-37.9-112-1.5-9.4-4.2-18.8-8.6-17.3,3.3-5,6.8-4,9.4,.5-.2-2-.5-3.9-.7-5.7-2-2.2-4.6-3.4-6.5-2.3,1.9-2.6,4-3.3,5.8-2.6-2.5-15.3-5.5-27.4-5.1-48.6,1.1-62.8,40.4-135.4,97.2-153.2h0Zm-77.1,220.2c2.1-2.6,5-3.3,7.1-2.7-.3,2.4-.1,4.2,.4,5.4,.8-1.5,2.4-2.9,2.7-2.7,6.7,4.5-10.2,27.8-3.8,48.1,2.5-23.3,15.3-31.4,18.6-51-1.6-5.8-5-6.7-7.4,0-1.9-9.3-5.9-8.2-8.6-4.2-6.1-4.4-9,0-8.9,7.1h0Zm54-95.2c10.9,.3,12.9-2.6,13.5-8-4.5,2.7-9,5.3-13.5,8Z"
          fill="url(#linear-gradient-38)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_48">
        <path
          d="M226.8,625.1c-16.2,23-4.1,46,4.9,68.8-11.2-2.8-20.7-11.6-24.6-23.2-2.8,14.8,7.2,25.4,6.6,35.7-21-14-23.5-28.3-34.3-41.2-1.5,8.2-1.8,14.7-.5,23.5-22.8-15.7-33.7-41.4-32.8-80.5h0s0,0,0,0c0-3.8,.3-7.8,.6-11.9,.9-31.5-3.2-63.9-1-97.3,3.9-59.5,28.2-101.2,61.5-131.3,14.3-13,30-17.8,47.1-32.9-7.1,26.6-50,63.8-59,84.6,16.1-10.6,39.3-35.3,54-53.5-8,31.9-34.5,60.8-47.4,77.8,6-.2,18.6-11.3,32.6-26.7-.6,6.3-5.9,20.5-10.8,30.4-3,1.4-8.6-1.3-11.2-2.3-2.3,6.8-.4,12.5,1.9,15.8-3.3,4.1-6.4,8.4-9.3,13.9,0,0,.2-.1,.2-.2,6.9-5.5,14.3-10.3,21.8-14.2-4.6-.5-10.8-6-12.2-10.3,10.7,5,25.5-1.5,31.4-4.1,18.2-8.3,38.7-19.2,49.9,1.1-13-4.2-26.4,12.3-38.2,27.5-13.7,17.6-30.9,36.5-38.5,60.2,61.9,56.8,137.7,65.1,206-13-8.8,36.8-28.8,75.6-66.4,88.6,9.6,3.9,32.7,0,51.3-14.1-9.8,36.2-38.3,40.7-59.3,38.9,7.2,9,22.2,13.1,40.3,14.7-14.8,17-50.5,15.9-70.8-7.3,2.4,10.4,11.6,19.7,24.1,28.6-21.2,2.3-54.8-13.7-65.2-31.7,4.2,14.2,12.3,22.2,24.7,29.5-18.4,.9-40.5-13.7-54.4-29.8h0c-7-8.2-17.3-13.8-23-14.1h0Zm-62.6-55.7c2.2-2.6,4-.8,6.2,0,.3,7.9-8,42.1-1.4,62,6.1-26.3,23.8-53.1,28-73.4-5.7,.7-9.8,1.1-13.5,4.9-.8-5.2-2.2-9-5.1-10.6-2.4,2.4-4,6.2-4.6,10.1-1.3-3.6-3.5-6.1-5.1-6.9-2.4,3-4.3,9.1-4.4,13.9h0Zm79.9-111.7c11.9,0,13.9-.2,13.5-8-4.5,2.7-9,5.3-13.5,8Z"
          fill="url(#linear-gradient-39)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_49">
        <path
          d="M336.5,272.3c-12.7,28.3-60.8,51.8-74.6,73.4,19.8-8.9,58.7-26.9,78.3-44.5-11.2,36.3-61.4,59.5-78.7,76.2,7.6-.9,14.8-3.2,32-14.9-5.8,28.7-48.3,49.5-53.6,61.6,24.3-7.9,49.7-21.6,72.3-28.9-5.8-1.6-12.6-10-13.6-15.7,12.7,8.6,33.1,3.4,41.1,1.2,25.1-6.9,53.6-16.6,63.7,11.8-11.5-5.9-21.2,0-37.1,12.9-31.2,25.5-59.8,52.5-86,83.1-7.4,8.6-14.8,18.6-6.8,25.8,43.1,38.8,85.6,34.9,140.4-5.7,17.5-13,29.6-36.7,52.8-50.7-22.4,35.9-19.7,78.1-84.5,126.3,34.4-5.5,46.8-38.3,70-48.8-17.4,38.5-56,99.1-79,93.4,11.9,6.7,29.7,4.5,47.8-3.2-12.5,24.1-54.1,35.6-81.5,13.8,4.2,5.6,9.3,10.5,20.6,16.6-24-1.2-58.9-13.5-67.2-35.4,1.6,14.3,11.9,22.4,24,28.9-20.6-2.3-41.5-9.7-54.1-30.1-5.4-1.4-9.8-5.8-23-1.7-3-8.2-1-17.7-.5-24.7-23.3,20.4-27.7,50.2-21.7,77.3-9.6-5-13-13.9-15.1-24.6-4.8,17-1.8,23.1-4.1,34.5-20.9-19.4-16.1-38.1-21-53.8-4.4,7.8-9,13.8-11.7,23.7-28.9-45.5-20.7-89.8-8.9-146.5,2.5-11.9,5.1-28.7,3.9-37.2-14.8-97.9,101.8-154,175.8-194h0Zm-1.4,120.1c15.3,3,17.8,3.2,19.2-6.8-6.4,2.3-12.8,4.5-19.2,6.8h0Zm-146.8,98.5c-1.3,3.7-1.1,5,.6,6.9,.8-2.3,.9-3.6-.6-6.9h0Zm-20.1,121.3c4.1-10,9.7-22.5,16.9-35.3,2.9,5.8,3,11,5,15.5,9.7-32.1,41-58.9,47-71-7.1-2.2-24.8-5.2-32.6-1.3,6-11.5,8-30.2,11.2-38-9.3,4.7-20.8,16.8-27,29.7-.8-4.2-4.9-13.5-8.4-17.7-4,34.2-20.1,82.2-12.1,118.1Z"
          fill="url(#linear-gradient-40)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_50">
        <path
          d="M376.9,255.6c-14.5,32.3-62.7,54.7-84.3,75.1,22.6-10.2,82.1-27.4,107.7-42.2-8.7,28.1-47.3,45.9-71.9,57.7-5.9,29.3-45.1,43.7-50.1,61.2-1.3,4.6,5.2,4.4,8.3,3.3,24-8.2,39.8-16,63.4-23.6-6.6-1.9-14.3-11.4-15.5-18,14.5,9.8,37.7,3.9,46.9,1.4,28.6-7.9,61.1-18.9,72.7,13.5-13.1-6.7-24.2,0-42.3,14.7-35.6,29.1-64.9,52.1-94.7,87-32.1,37.5,20,38,48.2,25.9,85.1-36.6,98.3-114.3,148-134.6-25.6,41-17.9,108-82,175,25-10,35.3-35.9,61.8-47.9-17.6,46-31.9,93.1-71.5,126.3,12.9,.5,24-2.2,37.4-14-5.8,20.4-21.2,41.3-48.7,37.6-7.2,7.3-26,15.8-45.6,8.5-22.9,6-58.4-13.3-67.4-27.3-11.4-4.8-27.6-7.4-42.9,.2-.4-16,14.3-25.9,14.9-34-38.8,1.1-60.5,34.8-53.7,65.7-10.9-5.7-10.8-20.4-13.2-32.6-5.7,13.8-3.4,25.4-6,38.3-15.4-18.3-12.9-32.9-18.6-50.7-2.1,1.7-5.2,4.5-6.9,7-24.7-47.7-17.3-71.5-4.1-126.1,10.7-44.3,6.9-53.8,11.9-90.7,11.8-86.6,123.2-115.8,198.3-156.5h0Zm-2.2,129.6c20.6,3.2,24,3.3,25.4-10.2-8.5,3.4-16.9,6.8-25.4,10.2h0Zm-180.9,77.2c-1.4,5.4-6.2,14.3-2.7,17.5,4.8-6,7.9-11.3,2.7-17.5h0Zm-18,144.6c3.9-8.9,8.6-26,15.4-36.3,.5,8.9-5.6,32.3,1.1,46.6,11-36.6,82.8-76.6,89.6-90.4-15.4,.5-37.6-20.4-63-15.2,4-7.1,16.6-6.7,19-21.7,17.9-14.3,45.9-21.4,53.7-50.6-16.7,3.2-56,20.4-64.6,43.8-15.7,5.7-27.9,17.6-30.5,23-.9-4.8-11.9-20.1-16-24.8-4.5,39-13.9,84.6-4.7,125.6Z"
          fill="url(#linear-gradient-41)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_51">
        <path
          d="M413,244.8c-14.7,34.4-32.5,34.5-58.4,52.3,24.7-10.5,70.7-18.5,98.1-34.3-9.7,32-43.1,37.9-69.5,50.5-12.7,19.3-69.8,45.6-85.7,64.3-8.8,10.4-7.9,21.3,10.1,14.8,21.4-7.8,34.5-14.1,57-21.3-7.1-2-21.2-12.6-20.9-23.4,15,14.6,41.7,7.8,54.6,5.6,41.7-7.2,74.5-16.3,77.8,14.4-14-7.2-26.7,.1-45,17.2-37.2,34.8-54.8,51.7-86.8,89.1-25.3,29.5,5.7,23.1,29.1,11.2,87.4-44.4,136.4-166.3,189.7-188-27.4,43.9-32.3,116.8-95.7,196.7,21.1-7.7,26.6-25.2,55.4-36.1-15.6,40.9-32.3,109-61.7,151.3,5.7-5,9.7-7.1,16.5-13.1-4.8,22.8-15,49.8-46,56.9-7.7,7.8-15.2,6.6-42.7,8.5-24.4,.9-67.3-9.9-80.8-24.9-12.2-5.1-42.2-4-58.8,8.1-4.4-31.2,30-31.5,28.9-44.2-41.5,1.2-61.6,49.1-54.3,82.2-11.7-5.1-17.1-10.7-19.6-23.8-2.6,11-.6,17.8-2.6,29.1-16.4-19.6-19.4-36.1-21.7-57.1-1.6,.9-2.7,2.2-3.4,3.1-26.5-51.1-22.3-80.3-8.2-138.9,11.5-47.5,7.3-57.6,12.8-97.2,12.7-92.8,151.4-109.4,231.8-152.9h0Zm-23,127.2c22.8,3.6,26.6,3.7,28.1-11.3-9.4,3.8-18.8,7.5-28.1,11.3h0Zm-189.9,58.6c-6.4,6.6-4.2,22.1-6.3,31.7,7.6-5.4,19.5-17.1,6.3-31.7h0Zm-24.5,182.8c3.2-8.5,5.1-15,12.2-25.3,.6,9.5-.8,24.8,6.5,40.1,11.8-39.2,118.4-96,125.7-110.8-10.7,.4-87.1-13.1-70.8-29.5,4.3-4.3,7.7-10.2,8.8-18.7,28.8-14.7,79.5-7.2,97.8-70.9-22.7,14.1-100.6,21.7-106.9,66.2-16.3,4.2-31.6,15.8-40.5,24.6-3.9,3.9-10.1,4-13.2-.8-4-6.3-9.3-13.3-11.9-16.4-4.9,41.8-17.5,97.6-7.7,141.5Z"
          fill="url(#linear-gradient-42)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_52">
        <path
          d="M428.8,243.5c-9.7,25.7-17.3,34.7-43.6,50.8,25.5-6,95.8-25.6,123.2-41.4,.4,41.8-108.8,71.8-149.6,90.8,.3,.3,.7,.6,1.1,.8h0c15.6,9.9,40.6,2.3,52.3,0,41.6-8,71.2-14.7,80,4.6,.2,.3,.3,.6,.5,1,24.7-31.9,50.5-58.9,87.2-65.6-27.4,43.9-33.8,100.3-97.2,180.2,19.7-10.4,22.9-19.9,47.3-24.7-15.6,40.9-29.2,79.2-53.5,128.1-3.3,36.1-23.6,68-64.5,70.8-24.4,.9-67.3-9.9-80.8-24.9-9.4-1.8-25.1-4.2-34.5,0-24.8,11.2-33.7,42.4-54.3,52.7-13.9-41,18.4-62.9,42.7-69.4,9.6-2.6,7.9-10.3,1.2-18.2-41.5,1.2-66.7,76.1-59.4,109.2-10.6-2.9-14.7-5.5-20.3-13.9-.5,7.5,1.6,13.3,1.1,21.8-23-19.1-25.6-44.5-27.9-65.5-1.6,.9-2.7,2.2-3.4,3.1-26.5-51.1-22.3-80.3-8.2-138.9,11.5-47.5,7.3-57.6,12.8-97.2,12.7-92.8,167.1-110.7,247.6-154.2h0Zm58.6,112.1c-11.5-2-24.9,7.3-39.5,21.7-30.2,29.8-51.6,52.4-75.9,79.7-13.7,15.4-7.8,18.1,9.3,9.3,47.1-23.9,76.6-70.3,107-110.5-.3,0-.6-.2-1-.3h0Zm-131.1-10.8c-2.7,1.3-5.2,2.5-7.4,3.6-18.1,9-35.5,17.4-43.1,26.4-8.8,10.4-6.5,18.3,11.5,11.7,21.4-7.8,43.2-18.7,65.7-26-11,1.9-22.8-5.2-26.3-13.9-.2-.6-.4-1.2-.5-1.7h0Zm-89.3,109.6c53.5,.7,131.4-25.5,150.3-88.7,13.9,1.7,17.7-.9,18.9-13.3-12.9,5.2-26.1,11.1-38.1,15.5-40.7,14.7-119,39-131.1,86.5h0Zm-.1,18.3c-16.8-2.8-23.1,1-23.3-19-14.1,5.1-28.5,24.5-36.4,32.3-3.9,3.9-7.7,3.6-10.7-1.2-4-6.3-10.7-14.7-14.7-17.6-2.6,51.2-27.1,107-6.4,152.4,3.2-8.5,5.6-13.7,10.1-22.3-.3,10,1.9,25.4,9.6,39.5,4.6-13.8,26.6-33.9,46.1-43.3-3.7-3.9-7.9-5.6-2.5-9.3,41.5-28.2,97.5-56.5,105.2-76.6-10.7,.4-83.8-4-81.2-19.7,.8-4.9,2.1-10.2,4.2-15.3h0Zm-60.8-52.8c-3,6.8-9.3,11.2-10.2,20.7,7.6-5.4,18.7-7.8,10.2-20.7Z"
          fill="url(#linear-gradient-43)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_53">
        <path
          d="M434.6,243.7c-4.6,22.4-13.9,30.2-28,45,25.5-6,116.4-40.2,143.8-56-.8,69-125.8,96.2-180.6,115.6,14.9,3.2,33-2.1,42.5-3.9,37.6-7.3,71.7-23.6,83.3-10.5,17.7-22.8,35.3-46.5,95.7-57.5-27.4,43.9-39.3,83.8-95.3,168.6,15.2-8.6,17.7-10.1,38.5-13.5-19.2,39.9-39.2,83-63.4,131.9-14.1,30.8-21.2,64.2-54.9,68.6-24.4,.9-57.6-15.3-74-30-25.7-9.1-37.3-2.6-46.8,1.6-24.8,11.2-27.1,36.9-46.3,52.4-10.7-34,20-49.5,44.4-56.1,9.6-2.6,4.1-17-6.9-21-14.5,.4-23.2,5-33.1,18.5-1.4-9.8-7.2-18.4,7.5-27.8,36.8-23.5,85.3-60.1,91.7-76.7-10.7,.4-75.2,5.3-72.6-10.3,.8-4.9,13.3-22.8,21.4-28.6-16.8-2.8-40.9,2.5-41.1-17.5-14.1,5.1-36.3,18.5-44.3,26.3-21,20.5-19.8-70.4-18.9-77.2,12.7-92.8,157-98.6,237.4-142.1h0Zm20.7,101.7c-2.2,2.4-5.8,2.9-12.2,2.3-58.5,12.3-145.4,49.3-158.3,99.9,77.8,7.4,152-48.3,183-103.8-3.9,.4-8.1,.9-12.6,1.6h0Zm-208.7,261.6c-22.9,4.6-40.9,28.8-48.7,52-8.9-14.2-4.5-72.3-8.5-84.4-4.5,8.6-13.2,37.6-14.7,51.6-23.6-51.6,16.7-140.8-2.4-168.8,1.6,12.3,0,25.4-3.8,37.5-18.3,58.9-22.8,88.2,8.2,138.9,.8-2.7,3.7-5.2,5.4-6.5,2.3,21,2.8,41.8,22.5,61.6,.5-8.5-4.1-15.1-3.5-22.7,5.8,8.2,14.1,16.2,24.4,19.5-4.5-20.3,4.9-53.5,21.1-78.6h0Zm240.9-251.4c-9-1.5-19.2,3.8-30.1,13.1-23.8,20.1-38.2,42.8-58.2,65.4-22.6,25.5,82.7-69.6,89.3-78.3-.3,0-.6-.2-1-.3h0Zm-145.3,20.9c15.6-6.5,24.7-10.8,41-16.1-9,1.6-18.6-3-23.7-9.5-8.4,4.5-59.6,43.1-17.3,25.5h0Zm-136.1,37.9c.4,4.3,.5,5.3-2.8,10.4,7.5-4.9,8.2-7.7,2.8-10.4Z"
          fill="url(#linear-gradient-44)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_54">
        <path
          d="M437,242.7c-2.1,16.4-5.8,28.5-18.8,44.3,24.8-8.2,129.5-54.6,158.2-66.5-.5,43.3-61.8,81.8-119.7,104.2-34.4,13.3-63.7,11-82.2,1.3,5.3,14.5,28.4,20.4,37.8,18.6,37.6-7.3,95.8-16.4,107.3-3.3,2-17-14.6-22.4-26-23,15.3-17.1,55.4-35.7,101.5-44-25.7,44.2-44.2,80.4-90.4,164.5,13.4-8.8,24.1-10.4,34.1-9.9-29.8,54.2-61,121.8-92.1,174.8-8.4,14.3-13.1,24.5-27.8,26-24.4,.9-47.1-21.5-63.6-36.2-16.1-5.7-39.8,3.5-47.8,5.7,4.7-7.7,2.4-18.5-6.9-26.4-14.5,.4-15.6,1.7-26.7,6.9,5-40.9,66.5-40.5,90.5-90.9-10.4-19.2-51.5-2.9-73.6-10.7-.5-7,2.5-17.9,10.6-23.7,1.4,4.4,3.1,7.1,6.7,9.6,60.5-3.2,88.2-40.7,101.2-47.1-11.4,13.2-15.3,18.1-19.6,36.2-.5,2.2,109.5-111.2,109.2-111.3-16.4-2.8-47.6,31.7-31.1,2.2-3.8,.4-7.9,.9-12.2,1.6-2.7,7.3-17.9,6.5-24,7.9,1.3-1.3,3.2-2.8,4.4-4-45.8,10.8-113.7,43.2-152.2,47.8,11,12.2,38.8,15.2,55.1,13.9-19.6,8.8-41.2,15.9-59.2,20.1-.8-1.8-1.2-4-1.3-6.7-3.6,1.3-5.6,1.2-10.4,2.9-53.7,19.7-66.2-71.1-32.9-99.5,47.1-40.1,141.6-52.5,201.9-85.1h0Zm-152.4,364.1c-18.2,1.9-35,10.5-32.3,41.9,18-14.6,17.6-30.2,32.3-41.9h0Zm-37.4-12.1c-9.9,12.1-26.7,21.6-36.1,36.1-10.1,19.1-2.6,40.4,14.3,55-2.6-11.9,.4-32.4,7.5-51.7,4.9-13.4,16.2-29.2,14.4-39.3h0Zm-52.1,78.6c.7-7.6,1.9-10,.2-20.3-6.6-23.8,10.6-71.7,.5-93.5-9.7,18.4-17.3,48.9-15.6,69.3h0c.6-.6,1.3-1.1,1.8-1.5,2,17.8,1.1,29.1,13.1,46.1h0Zm-32.1-71.8c2-37.3,39.5-143.1,5.2-166.4,6,26.1,3.9,47.7,.2,59.8-15.9,51.3-17.9,69.3-5.3,106.6h0Zm228.6-241.4c-9.1,1.6-24.8,.8-35.8-1.4-59.4,31.9,9.5,9.9,35.8,1.4Z"
          fill="url(#linear-gradient-45)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_55">
        <path
          d="M436.9,221.3c11,27-2.1,51.6-18.6,65.6,18.3-6.1,94.3-40.9,144.4-62.6,23-10,32.4-21.9,36.2-29.2,10.4,41.2-48.9,84.8-95.2,108.5,3.6,1.1,7,.8,9.1-.4,20-12.1,55.1-29.7,86.1-33-25.7,44.2-44.3,77.8-90.5,161.8,13.7-10.4,23.6-12.5,32.9-15.5-.7,13.2-11.4,28.1-18.2,41-25.1,48.1-51.7,104.2-76.3,145.9-8.4,14.3-13.1,24.5-27.8,26-24.4,.9-45.8-42.1-55.1-66.6-19.1,3.8-38,19.8-46.1,21.8-1.5-24.6-12.6-40.1-26-33.7,16.6-31.9,48.8-12,72.9-62.3-8.2-15.2-21.7-10.6-37.9-11.9-10.5-.9-15.9-1.5-20.5-10.4,41.6,11.1,75.5-26,86.4-31.4-4.5,9.1-4,12.5-2.7,18,.5,2.3,97.4-104.5,109.2-111.3-.2,0-25.5-4.5-39.2-2.4,3.2,17.4-21.5,10.8-28.7,11.8,5.4-3.9,22.6-13.6,26-14,30.7-4.2,61.1-5.7,68.7,2.9-6.8-27.8-27.5-24.3-49.1-22.1-15.5,4-35.5,8.8-51.2,11-30.7,4.4-42.6,.6-54.4-9.3,5.3,14.5,31.9,26.7,41.4,24.9,7.9-1.5,13.4-2.6,22.9-4.2-18.8,11.6-46.1,24.3-67.9,20-12.8,7.3,.6,4.8,7,6.1-41.1,16-78.1,24.2-116,17.9,11,12.2,61.1,30.4,125.2-4.5-14.7,17.5-28.9,29.4-42.3,35.1-79.1,33.4-126.7-59.7-91.8-86.8,29.2-22.7,95.6-45.8,151.1-69.3,13.3-5.6,29.9-19.5,36.3-37.5h0Zm-152.2,385.5c-7.7-3.4-14.5-3.4-19.2-4.9-5.7,4-9.3,12.7-7.8,30.1,16.5-18.2,12.2-13.5,27-25.3h0Zm-49-45.1c1.4,15.2-.5,26.9-9.6,43.1-9.3,16.4-10.4,25,.9,50,1.4-6.8,3.4-13.9,6-20.8,4.9-13.4,31.2-45.5,2.7-72.3h0Zm-43.9-25.4c12.9,29.5,5.5,63.6,3.1,86.4-18-14.7-14-46.2-9.4-60.5,2.4-7.4,5.1-15.2,6.3-25.9h0Zm-35.1-122.5c2,8.9,4.5,13.1,7.9,15.8,28.5,22.8,18.7,62.6,12.8,95.5-2.5-25.8-5.3-53.6-13.8-70.9-9.2-18.8-11.8-28.8-6.9-40.4Z"
          fill="url(#linear-gradient-46)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_56">
        <path
          d="M423.5,202.9c27.5,23.9,19.4,52.7-2.2,82,18.6-6.2,90.9-38.7,138-59.6,15.6-6.9,29.2-28.4,32.1-45,22.4,29.5-4.7,63.5-24.3,80.1-13,11-33.1,25.8-49.4,35,4.8,1.1,11.4-.5,21.7-4.9,16.8-7,45.1-26,63.7-25.2-25.7,44.2-52.2,88.6-98.5,172.7,19.2-9,19.2-19.5,22.8-28,1.8-4.1,5.9-8.9,10.7-10.6-.6,3.5,.5,5.7,1.5,7.7,6.7,13-7.7,33.4-13.8,44.5-28.2,51.4-63.8,116.2-90,163.6-2.4,4.4-3.4,6.5-6.1,8.7-2.2,1.8-5.2,3.2-10.5,3.7-24.4,.9-48.2-64.1-39.5-101.6-19.1,3.8-27,17.3-33.5,33.9-10.5-14.2-20.9-11.5-28-23.9,13.2-31.9,27.8-3,51.9-53.3-3.8-8-18.2,.7-27.2-13,18-1,24.2-6.5,35.1-11.8,.1,8.6-1.2,13.4,.5,18.9,.7,2.2,110.7-135,126.6-139,0,0-.1-3-3.4-3.7,9.2-.9,20.4,1.5,24.8,6.5-3.9-20.9-18.7-25.7-33-25.7-5.6,0-11.5,1.4-17,2.4-8.7,2.3-18.3,3.7-28.3,5.9-7.7,1.7-17.9,3.6-24.7,4.6-11,1.6-19.6,2.2-26.7,1.6-12.8-1-20.4-5.4-26.7-11.9,.8,18.3,24.3,30.4,42.2,26.5,2.6-.6,5.2-.7,6.3-.7-18.4,8.2-27.4,12.2-43.2,16.6-9,4.9-12,5.7-14.5,8.8-29.3,11.4-49.7,17.4-72.7,17.6-22.2,.2-63.7-39.5-38.8-58.8,29.6-23,96.9-47.2,152.6-70,32.7-13.4,7.2-37.8,21.7-54.6h0Zm-165.3,180.8c7.3,10.6,38.5,48.8,87.7,30.6,13.7-5.1,42.7-30.2,57.4-47.7-68.4,38.9-105.4,42.3-145,17.1h0Zm172.5-33.3h16.4c7.1,0,12.3-1.3,12.3-9.4v-6.3s-28.7,15.7-28.7,15.7h0Zm-158.5,256.6c3.7,1.4,9.9-.5,6.3-6.6-2.7-4.4-4-9.6-7.1-10.5,.5,6.7-10.4,9.9-12.6,21.1,5.5-5.8,10-5.3,13.4-3.9h0Zm-48.8-69.2c-13.3,28.5,15.1,51.5,17.6,78.6,5-8.4,9.9-22.4,6.1-36.6-3.1-11.6-15.9-20.2-23.7-42h0Zm-35.8-6.6c19.6,20.7,9.5,44.2,9.4,66.6-16.2-16-16.3-22-11.7-36.3,2.4-7.4,4.5-18.4,2.3-30.3h0Zm-23.3-130.8c.2,12.4-6.8,13.9,4.2,33.8,5.9,10.8,1.9,22.8-1.5,28.6-1.5-5.3-1.7-5.1-3.7-9.3-9.2-18.8-15.5-35.9,1-53.1Z"
          fill="url(#linear-gradient-47)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_57">
        <path
          d="M426.7,185.5c-3.1,18.1,27.6,30.1,4.6,59.7-10.9,14-6.6,36.5-9.8,40.3,17.8-5.9,84.6-36.1,131.7-56.8,10.6-4.7,15.9-10,17.3-21,3-22.3,15.9-28.6,9.6-46.2,8.6,6.9,21.8,23.6,19.8,45-2.8,31.6-22.9,44.4-29.6,51-3.9,3.9-8.3,7.6-13.1,11.2-11.1,8.3-17.3,12.8-30.7,21.9,8,2.3,17.9-4,34.7-12.2,13.1-6.4,31.3-22.3,43.9-21.7-6.7,17.5-19.6,35.7-32.5,58.2-10,17.5-20.2,36.1-31.7,57.2-4.4,8.1-6.8,14.7-4.3,24.1,1.9,7.4,6.1,13.1,4.8,20.3-3.3-6-9.7,0-17.2-5.2-1.1-.8-2-1.9-2.8-3.4-4.6,8.4-9.4,17.2-14.5,26.4,17.5-15.5,25.9-13.6,34-14.2-2.9,14.6-13.6,28.9-21.8,44.2-26.9,50.3-59.9,109.1-83.2,151.3-2.4,4.4-3.4,6.5-6.1,8.7-2.2,1.8-5.2,3.2-10.5,3.7-1.4,0-3.2-.1-5.1-.8-3.4-1.2-7.1-3.6-9.1-7.3l-9.9-18c-2.6-4.8-6-10.5-8.1-17.2-7.3-23.3-9.3-56.5,.2-85.4-17.4,6.6-23.3,37.4-29.2,57.5-10.1-13.7-24.3-35.9-6.5-49.5,4.6-3.5,9.2-7.6,13.3-12.6,30.6-37.4,62.4-73.6,94.2-110.1,4.8-5.5,13.8-17.9,23.4-28.5,8.2-9.1,16.9-16.7,22.5-18.1-.1,0,11.9-5.6,21.4,2.8-3.9-20.9-18.7-25.7-33-25.7-5.6,0-11.5,1.4-17,2.4-8.8,1.7-17.8,3.1-27.8,5.3-7.7,1.7-19.6,4-26.4,5-11,1.6-18.4,2.3-25.4,1.7-12.8-1-20.4-5.4-26.7-11.9,.4,6.5,3.4,11.5,6.9,15.3,5.3,5.7,11.6,9,18,10.6,6.9,1.7,13.8,1.4,19.7,.1-32.9,14.7-86,41.5-119.1,38.1-17.7-1.9-37.9-19-43.3-37.3-3.6-12.2-2.5-17,17.8-28.3,33.2-18.5,85.6-37.9,130-56.9,5.6-2.4,14.5-7.5,18.1-15.6,6-13.4-8.6-36.2,8.5-58.6h0Zm-71.2,279.5c11.3,7.5,22,6.6,21.6-15.9-11.1,4.5-5.4,11.9-21.6,15.9h0Zm-77.7-70.6c19.1,27.8,54.2,33.8,81,13.4,15.2-11.5,39.6-34.6,52.6-46.1l-50.5,27.3c-36.9,20-60.2,16-83.2,5.4h0Zm153.1-43.4h16.4c7.1,0,12.3-1.3,12.3-9.4v-6.3s-28.7,15.7-28.7,15.7h0Zm-164.6,248.3c3-3.2,8.9-6.2,5-9.8-1.9-1.8-5.5-3.3-7.6-7.2-3.3,5.1,4,8.5,2.6,17h0Zm-34.9-79.7c11.5,23.2-8,34.1-7.9,51.6-2.9-7.5-5.3-14.9-2-27.8,1.7-6.6,6.9-11.3,9.9-23.8h0Zm-50.1-1.8c3.8,22,13.5,4.9,17.6,51.7-22.4-16.4-24.9-42.7-17.6-51.7h0Zm-14.4-140.8c4.4,2.5,7.4,5.3,9.3,8.3,12,19.7-19.1,28.4-21.1,32.8,1.1-5,2.5-10.4,9.4-17,2.4-2.3,7.6-9.4,2.4-24Z"
          fill="url(#linear-gradient-48)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_58">
        <path
          d="M430.9,351l28.7-15.7v5.9c0,8-4.1,9.7-14.3,9.7h-14.5Zm96.2,47.3c1.2,1.6,2.9,3.2,5.1,4.6-1-12.9,3.5-19.7,7.4-27.2l62.9-113.1c-15.6-2.9-37.7,15.4-51.6,22.7l-8.7,4.6c-7.9,4.1-13,3.9-18.4,2.3l17.2-11.7c28.4-19.6,56.1-45.2,44.7-73.2-2-4.9-3.9-6.4-4.9-12.1-1.9-10.8,4.7-18.5,5.6-22.2,4.8-19.3-17.6-22.6-7-41.3-16.9,7.1-23,31.1-7.8,46.8,9.9,10.2,8.4,25.1,1.1,36.3-3.1,4.7-6.8,8.2-12.9,10.8l-138.4,60c3-9.7,4.4-29.6,2.3-46.4-1.8-14.3,1.6-28.9,5.9-42.1,3.6-10.9,1.4-23-4.8-30,.3,15-13.7,21.3-9.7,33.9,12.6,39.4,3.4,49.8-14.7,57.8l-133.8,59.7c-7.9,3.5-12,11.9-10.7,19.9,1.9,11.4,16.4,30.5,26.2,36.3,16.9,10.1,38.4,8.8,52.5,2.8l79.1-33.4c-24.8,5.3-43.4-12-43.8-26.2,12.4,13.2,31.8,14,52.9,10l59.8-11.4c24.1-4.6,40.3,4.1,43.6,24.5-17.8-13.6-36.5,6-47,19.2l-106.8,133.4c-23.6,29.5-11.3,47-3.5,61.2,0-22.9,5.6-42.6,18.3-60.4l8.9-12.5c-14.2,30.4-19.3,79.7-2.3,110.6l12.8,23.3c2.7,5,8.7,8.7,14.4,8.5,6.2-.3,11.5-3.1,14.5-8.6l93-168.3c4.5-6.9,8.2-14.3,10.7-22.2-12.9-1.5-23.1,2.6-33.9,11.3l21.3-38.3h0Zm-298.4,98.2c-16.9,20.1-5.1,19.1,4.8,28-1.7-14.6-9.1-12.1-4.8-28h0Zm34.4,85.3c-1.4-4.4,1.1-5.7,4.6-9.8,1.3,8.7-1.8,5.3-4.6,9.8h0Zm-77.9-70.7c-.7,9.1,.2,11.4,1.2,15.6,1.6,6.9-.7,9-5,18.7-5.7-10.8-4.7-25.3,3.8-34.3h0Zm-19.1-140.8c.8,1.8,16.2,16.2,3.1,20,.2-2.3,0-5.1-.7-8.2-.3-1.6-3.5-6.3-2.4-11.7h0Zm204.2,62.3c2.4,3.5,6,6,6.3,11.7-6.3-2.9-10.7-7.2-6.3-11.7h0Zm41.2-71l-49,43.2c-22.2,19.6-58.6,21.4-76.1-6.9,13.4,6.2,39.6,9.9,67.2-5l57.9-31.3Z"
          fill="url(#linear-gradient-49)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_59">
        <path
          d="M430.9,351l28.7-15.7v5.9c0,8-4.1,9.7-14.3,9.7h-14.5Zm147.1-185.9c-7.7-26.9,11.1-35.1-3.3-47.4,.4,15.1-26.9,28.8,3.3,47.4h0Zm-158.2,14.7c1.4-12.4-10.5-20.2,.4-30.3,.7,15.2,14,13.6-.4,30.3h0ZM228.8,475c3.9,8.9,6.1,10.4-.3,20.5,1.6-7.5-2.9-13.3,.3-20.5h0Zm-43.7,22.2c6.4,6.4,7.4,10.6-2.2,16.1,3.8-5.2,2-10.9,2.2-16.1h0Zm-18.2-130.7c.4-3.5,2.1-4.1,2.8-7.2,4,4.2-.1,5.5-2.8,7.2h0Zm203.3,66.1c-4.4,4.5,0,8.8,6.3,11.7-.3-5.7-3.9-8.1-6.3-11.7h0Zm41.2-71l-49,43.2c-22.2,19.6-60.9,21.1-78.7-7.8,13.4,7.9,42.2,10.9,69.8-4l57.9-31.3h0Zm-155-17.1l6.7,12.1c15.8,28.8,54.2,28.2,73.9,19.9l76.8-32.4c-24.8,5.3-43.4-12-43.8-26.2,12.4,13.2,31.8,14,52.9,10l59.8-11.4c24.1-4.6,40.3,4.1,43.6,24.5-17.8-13.6-36.5,6-47,19.2l-106.8,133.4c-23.6,29.5-11.3,47-3.5,61.2,0-22.9,5.6-42.6,18.3-60.4l8.9-12.5c-14.2,30.4-19.9,81.8-2.9,112.8l12.8,23.3c2.7,5,8.7,8.7,14.4,8.5,6.2-.3,11.5-3.1,14.5-8.6l103.4-189.2c-13.2-.8-24.8,3.3-34.1,9.9l20.6-37.4c1.8-3.3,4.2-8.1,5-12.1,2.6-1.6,4-4.2,5.8-7.5l63.9-114.1c-13.4-1.2-26.9,6.8-41.7,14.3l-17.5,8.9c-7.9,4-13.2,3.7-19.1,3l19.5-13.3c28.2-19.2,61.4-49.3,42-81.4-2.8,6.5-3.3,16.3-21.6,25.8l-141.2,61.2c9.3-11.8,12.6-37.3,8.6-53.2-5.4,12.8-16.5,20-30.6,27l-133.1,58.9c-4.7,2.1-8.5,6.2-10,11.1-1.4,4.6-1.1,10,1.4,14.6Z"
          fill="url(#linear-gradient-50)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_60">
        <path
          d="M430.9,351l28.7-15.7v5.9c0,8-4.1,9.7-14.3,9.7h-14.5Zm-7.7-214.5c-.3,9-9.7,13.3-3,21.7,2.1-10.8,11.4-8.2,3-21.7h0Zm155.6-36.8c-4.1,16.3,6.9,21.6-.2,32.7-5.3-12.1-20-21.6,.2-32.7h0ZM228.6,467.8c3.3,4.5,2.9,4.9-.7,10.7-2.3-6.4,1.4-4.7,.7-10.7h0Zm144-41.8c-1.5,1.5-3,1-1.2,3.6,.9-2.3,2.6-1.9,1.2-3.6h0Zm38.9-64.4l-49,43.2c-22.2,19.6-63.5,22.2-80.6-8.9,13.4,7.9,44.1,11.9,71.7-3l57.9-31.3h0Zm-157.3-16.1l6.7,12.1c15.8,28.8,54.2,28.2,73.9,19.9l79.1-33.4c-24.8,5.3-43.4-12-43.8-26.2,12.4,13.2,31.8,14,52.9,10l59.8-11.4c24.1-4.6,40.3,4.1,43.6,24.5-17.8-13.6-36.5,6-47,19.2l-106.8,133.4c-23.6,29.5-11.3,47-3.5,61.2,0-22.9,5.6-42.6,18.3-60.4l8.9-12.5c-14.2,30.4-21.4,83.5-4.4,114.4l12.8,23.3c2.7,5,8.7,8.7,14.4,8.5,6.2-.3,11.5-3.1,14.5-8.6l104.9-190.9c-13.2-.8-24.8,3.3-34.1,9.9l93-167.2c-13.7,0-24.6,2.9-38.5,9.9l-19.8,9.9c-8,4-12.9,4-18.8,3.3l20.4-13.9c28.2-19.2,57.1-47.5,49.3-77.5-4.6,9.2-12.7,14.8-21.4,18.6l-150.8,65.3c10-11,16-30.1,15.2-48.2-11.5,10.4-27.3,17.6-42.7,24.4l-127.9,56.7c-4.7,2.1-8.5,6.2-10,11.1-1.4,4.6-1.1,10,1.4,14.6Z"
          fill="url(#linear-gradient-51)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_61">
        <path
          d="M430.9,351l28.7-15.7v5.9c0,8-4.1,9.7-14.3,9.7h-14.5Zm143.3-249.2c4.2-12.1-5.1-12.3-.5-19.6,4.4,3.7,12.1,8.2,.5,19.6h0Zm-152.2,28.8c.7,5.5-1,5,1.3,8.4,4.2-2.7,.5-5.8-1.3-8.4h0Zm-10.6,231.1l-49,43.2c-22.2,19.6-63.5,22.2-80.6-8.9,13.4,7.9,44.1,11.9,71.7-3l57.9-31.3h0Zm-157.3-16.1l6.7,12.1c15.8,28.8,54.2,28.2,73.9,19.9l79.1-33.4c-24.8,5.3-43.4-12-43.8-26.2,12.4,13.2,31.8,14,52.9,10l59.8-11.4c24.1-4.6,40.3,4.1,43.6,24.5-17.8-13.6-36.5,6-47,19.2l-106.8,133.4c-23.6,29.5-11.3,47-3.5,61.2,0-22.9,5.6-42.6,18.3-60.4l8.9-12.5c-14.2,30.4-21.4,83.5-4.4,114.4l12.8,23.3c2.7,5,8.7,8.7,14.4,8.5,6.2-.3,11.5-3.1,14.5-8.6l104.9-190.9c-13.2-.8-24.8,3.3-34.1,9.9l90.4-164.5c-13.7,0-22.8,3.1-36.4,8.8l-19.3,8.1c-8.2,3.5-12.9,4-18.8,3.3l20.4-13.9c28.2-19.2,49.8-38.8,54-70.2l-176.8,76.6c10-11,16.9-27.1,18.8-44.3l-174.2,77.2c-4.7,2.1-8.5,6.2-10,11.1-1.4,4.6-1.1,10,1.4,14.6Z"
          fill="url(#linear-gradient-52)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_62">
        <path
          d="M430.9,351l28.7-15.7v5.9c0,8-4.1,9.7-14.3,9.7h-14.5ZM572.8,87.9c-.2-.6-.4-1.3-.3-2,0-1,1.6-4.6,2.8-6.7,2,4.3,0,6.6-2.4,8.7h0Zm-161.3,273.7l-49,43.2c-22.2,19.6-63.5,22.2-80.6-8.9,13.4,7.9,44.1,11.9,71.7-3l57.9-31.3h0Zm-157.3-16.1l6.7,12.1c15.8,28.8,54.2,28.2,73.9,19.9l79.1-33.4c-24.8,5.3-43.4-12-43.8-26.2,12.4,13.2,31.8,14,52.9,10l59.8-11.4c24.1-4.6,40.3,4.1,43.6,24.5-17.8-13.6-36.5,6-47,19.2l-106.8,133.4c-23.6,29.5-11.3,47-3.5,61.2,0-22.9,5.6-42.6,18.3-60.4l8.9-12.5c-14.2,30.4-21.4,83.5-4.4,114.4l12.8,23.3c2.7,5,8.7,8.7,14.4,8.5,6.2-.3,11.5-3.1,14.5-8.6l104.9-190.9c-13.2-.8-24.8,3.3-34.1,9.9l90.4-164.5c-13.7,0-22.8,3.1-36.4,8.8l-19.2,8.1c-8.2,3.5-12.9,4-18.8,3.3l20.4-13.9c28.2-19.2,49.8-38.8,54-70.2l-176.8,76.6c10-11,16.9-27.1,18.8-44.3l-174.2,77.2c-4.7,2.1-8.5,6.2-10,11.1-1.4,4.6-1.1,10,1.4,14.7Z"
          fill="url(#linear-gradient-53)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_63">
        <path
          d="M430.9,351l28.7-15.7v5.9c0,8-4.1,9.7-14.3,9.7h-14.5Zm-19.5,10.6l-49,43.2c-22.2,19.6-63.5,22.2-80.6-8.9,13.4,7.9,44.1,11.9,71.7-3l57.9-31.3h0Zm-157.3-16.1l6.7,12.1c15.8,28.8,54.2,28.2,73.9,19.9l79.1-33.4c-24.8,5.3-43.4-12-43.8-26.2,12.4,13.2,31.8,14,52.9,10l59.8-11.4c24.1-4.6,40.3,4.1,43.6,24.5-17.8-13.6-36.5,6-47,19.2l-106.8,133.4c-23.6,29.5-11.3,47-3.5,61.2,0-22.9,5.6-42.6,18.3-60.4l8.9-12.5c-14.2,30.4-21.4,83.5-4.4,114.4l12.8,23.3c2.7,5,8.7,8.7,14.4,8.5,6.2-.3,11.5-3.1,14.5-8.6l104.9-190.9c-13.2-.8-24.8,3.3-34.1,9.9l90.4-164.5c-13.7,0-22.8,3.1-36.4,8.8l-19.2,8.1c-8.2,3.5-12.9,4-18.8,3.3l20.4-13.9c28.2-19.2,49.8-38.8,54-70.2l-176.8,76.6c10-11,16.9-27.1,18.8-44.3l-174.2,77.2c-4.7,2.1-8.5,6.2-10,11.1-1.4,4.6-1.1,10,1.4,14.7Z"
          fill="url(#linear-gradient-54)"
          fillRule="evenodd"
        />
      </g>
      <g style={{ visibility: "hidden" }} id="_64">
        <path
          d="M430.9,350.7l28.6-15.6v5.9c0,8-4,9.7-14.2,9.7h-14.4Zm-19.4,10.6l-48.7,43c-22.1,19.5-63.2,22.1-80.1-8.8,13.3,7.8,43.8,11.9,71.3-3l57.5-31.2h0Zm-156.4-16l6.6,12.1c15.8,28.7,53.9,28.1,73.5,19.8l78.7-33.2c-24.7,5.2-43.2-12-43.5-26,12.3,13.2,31.6,14,52.6,10l59.5-11.3c23.9-4.6,40.1,4.1,43.4,24.4-17.7-13.5-36.3,6-46.7,19.1l-106.2,132.6c-23.4,29.3-11.2,46.7-3.5,60.8,0-22.8,5.6-42.4,18.2-60.1l8.8-12.4c-14.1,30.2-21.2,83.1-4.3,113.8l12.7,23.2c2.7,5,8.6,8.6,14.4,8.4,6.2-.3,11.4-3.1,14.4-8.6l104.3-189.8c-13.1-.8-24.6,3.3-33.9,9.8l89.9-163.5c-13.7,0-22.6,3.1-36.2,8.8l-19.1,8.1c-8.1,3.4-12.8,3.9-18.7,3.3l20.3-13.8c28-19.1,49.5-38.6,53.7-69.8l-175.8,76.2c9.9-10.9,16.9-26.9,18.7-44l-173.3,76.7c-4.6,2.1-8.5,6.2-10,11.1-1.4,4.6-1.1,10,1.4,14.6Z"
          fill="url(#linear-gradient-55)"
          fillRule="evenodd"
        />
      </g>
    </svg>
  );
};
