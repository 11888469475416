import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Icon,
  useColorModeValue,
  createIcon,
  Center,
  Image,
  Wrap,
  Flex,
} from "@chakra-ui/react";

import { LogoAnim } from "./LogoAnim";

import powered from "./assets/powered.svg";

import { AnimatedBackground } from "./AnimatedBackground";
import { useNavigate } from "react-router-dom";

export function Header() {
  return (
    <>
      <AnimatedBackground />
      <Center>
        <LogoAnim />
      </Center>
      <Center>
        <Box mt="-10vh" mb="150px">
          <Text
            sx={{
              fontFamily: "Greycliff",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "80px",
              lineHeight: "80px",
            }}
          >
            Internet Base
          </Text>
          {/* <Text
            sx={{
              fontFamily: "Hexaframe",
              textAlign: "center",
              fontWeight: "bold",
              fontSize: "19px",
            }}
            mt="5px"
          >
            DECENTRALIZED ASSEMBLY PLATFORM
          </Text> */}
          <Center mt="0px">
            <Image src={powered} />
          </Center>
        </Box>
      </Center>
    </>
  );
}
