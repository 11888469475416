import React, { useState } from "react";
import {
  Box,
  Button,
  Text,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";

const UnsubscribeComponent = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isUnsubscribed, setIsUnsubscribed] = useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef();

  const handleUnsubscribe = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const akey = urlParams.get("akey");

    try {
      const response = await fetch(
        "https://api.internetbase.org/api/unsubscribe/" + akey
      );
      if (response.ok) {
        setIsUnsubscribed(true);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    onClose();
  };

  return (
    <Box>
      {isUnsubscribed ? (
        <Text fontSize="xl" fontWeight="bold" textAlign="center" mt={4}>
          We're sorry to see you go! We appreciate your time with us and hope to
          have the opportunity to serve you again in the future.
        </Text>
      ) : (
        <>
          <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Unsubscribe
                </AlertDialogHeader>

                <AlertDialogBody>
                  Are you sure you want to unsubscribe from our mailing list?
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={onClose}>
                    No
                  </Button>
                  <Button colorScheme="red" onClick={handleUnsubscribe} ml={3}>
                    Yes
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </>
      )}
    </Box>
  );
};

export default UnsubscribeComponent;
