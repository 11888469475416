import { Page } from "./Page";
import { PageFramework } from "./PageFramework";
import { Box } from "@chakra-ui/react";
import "./App.css";
import PageUnsubscribe from "./PageUnsubscribe";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Header } from "./Header";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Page />,
  },
  // {
  //   path: "/caelumcore",
  //   element: <PageFramework />,
  // },
  {
    path: "/unsubscribe",
    element: <PageUnsubscribe />,
  },
]);

function App() {
  return (
    <Box sx={{ position: "relative" }}>
      <GoogleReCaptchaProvider reCaptchaKey="6Lfix8IlAAAAAP3SbPR8pva60CT6ydTq_9fc3pU7">
        <Header />
        <RouterProvider router={router} />
      </GoogleReCaptchaProvider>
    </Box>
  );
}

export default App;
