import { motion } from "framer-motion";
import styled from "@emotion/styled";

const draw = {
  hidden: { pathLength: 0, opacity: 0 },
  visible: (i) => {
    const delay = 1 + i * 0.02;
    return {
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { delay, type: "spring", duration: 0.7, bounce: 0 },
        opacity: { delay, duration: 0.01 },
      },
    };
  },
};

const Div = styled.div`
  path,
  line,
  polyline,
  polygon {
    stroke-width: 3px;
    stroke-linecap: round;
    fill: transparent;
    stroke-width: 3;
    stroke: #491ec4;
  }
`;

export function Draw() {
  return (
    <Div>
      <motion.svg
        width="600"
        height="600"
        viewBox="0 0 781.92 900.14"
        initial="hidden"
        animate="visible"
      >
        <motion.path
          variants={draw}
          custom={1}
          d="M235.3,372.17c4.5-24.74,16.87-42.61,42.18-60.62"
        />
        <motion.path
          variants={draw}
          custom={2}
          d="M566.37,372.17c-4.5-24.74-16.87-42.61-42.18-60.62"
        />
        <motion.path
          variants={draw}
          custom={3}
          d="M483.25,520.96c1.67,1.3,3.56,2.23,5.66,2.71,11.47,2.61,24.66-9.16,29.47-26.3,4.15-14.8,.7-28.75-7.7-33.97"
        />
        <motion.path
          variants={draw}
          custom={4}
          d="M482.08,510.2c.98,.63,2.05,1.08,3.21,1.35,8.13,1.85,17.5-6.5,20.91-18.66,3-10.7,.4-20.77-5.81-24.31-7.06,5.63-13.1,14.84-16.21,25.93-1.52,5.41-2.19,10.73-2.1,15.69Z"
        />
        <motion.line
          variants={draw}
          custom={5}
          x1="496.87"
          y1="492.83"
          x2="516.72"
          y2="502.28"
        />
        <motion.line
          variants={draw}
          custom={6}
          x1="503.89"
          y1="486.44"
          x2="519.91"
          y2="489.38"
        />
        <motion.line
          variants={draw}
          custom={7}
          x1="503.31"
          y1="478.9"
          x2="519.54"
          y2="478.22"
        />
        <motion.line
          variants={draw}
          custom={8}
          x1="501.37"
          y1="472.87"
          x2="515.2"
          y2="469.05"
        />
        <motion.path
          variants={draw}
          custom={9}
          d="M483.23,502.19c.64,.41,1.34,.71,2.1,.88,5.32,1.21,11.43-4.25,13.66-12.2,1.96-6.99,.26-13.58-3.8-15.88-4.61,3.68-8.56,9.7-10.6,16.95-.99,3.54-1.43,7.01-1.37,10.25h0Z"
        />
        <motion.line
          variants={draw}
          custom={10}
          x1="509.8"
          y1="514.11"
          x2="496.24"
          y2="501.21"
        />
        <motion.line
          variants={draw}
          custom={11}
          x1="500.9"
          y1="521.65"
          x2="491"
          y2="506.55"
        />
        <motion.line
          variants={draw}
          custom={12}
          x1="491.53"
          y1="524.02"
          x2="486.08"
          y2="508.33"
        />
        <motion.path
          variants={draw}
          custom={13}
          d="M519.46,463.01c-13.73-3.13-29.53,10.97-35.29,31.49-5.76,20.52,.7,39.69,14.43,42.82,13.73,3.13,29.53-10.97,35.29-31.49,5.76-20.52-.7-39.69-14.43-42.82Z"
        />
        <motion.path
          variants={draw}
          custom={14}
          d="M503.59,455.07c-13-.78-27.09,12.77-32.46,31.9-3.74,13.34-3.47,33.4,10.18,41.24l11.69,6.72c1.72,1.13,3.58,1.94,5.6,2.4,13.73,3.13,29.53-10.97,35.29-31.49,4.7-16.74,1.26-32.58-7.61-39.55-.82-.64-2-1.74-2.75-2.15l-11.56-6.3c-2.89-1.58-5.06-2.57-8.37-2.76h-.01Z"
        />
        <motion.path
          variants={draw}
          custom={15}
          d="M488.14,529.34c1.25,.68,2.6,1.19,4.03,1.51,12.88,2.94,27.71-10.3,33.12-29.56,4.61-16.42,.88-31.91-8.29-37.95"
        />
        <motion.path
          variants={draw}
          custom={1}
          d="M494.06,535.63l.02-.04c-.37-.2-.73-.42-1.08-.66l-11.69-6.72c-13.65-7.85-13.93-27.9-10.18-41.24,5.37-19.13,19.46-32.67,32.46-31.9,3.31,.2,5.48,1.18,8.37,2.76l11.56,6.3c.29,.16,.66,.43,1.03,.73-2.74-13.12-10.1-22.86-20.78-25.29-18.23-4.15-39.22,14.57-46.87,41.83-7.65,27.26,.93,52.72,19.16,56.88,5.89,1.34,12.07,.3,17.99-2.65h0Z"
        />
        <motion.path
          variants={draw}
          custom={17}
          d="M406.88,536.39l48-27.71m72.34-41.77l40.26-23.25"
        />
        <motion.path
          variants={draw}
          custom={18}
          d="M438.15,440.1c-3.2-.73-6.88,2.56-8.23,7.34-1.34,4.79,.16,9.25,3.36,9.98,3.2,.73,6.88-2.56,8.23-7.34,1.34-4.79-.16-9.25-3.36-9.98Z"
        />
        <motion.path
          variants={draw}
          custom={19}
          d="M438.15,583.44c-3.2-.73-6.88,2.56-8.23,7.34-1.34,4.78,.16,9.25,3.36,9.98,3.2,.73,6.88-2.56,8.23-7.34,1.34-4.78-.16-9.25-3.36-9.98Z"
        />
        <motion.path
          variants={draw}
          custom={20}
          d="M544.79,379.86c-3.2-.73-6.88,2.56-8.23,7.34-1.34,4.78,.16,9.25,3.36,9.98s6.88-2.56,8.23-7.34c1.34-4.78-.16-9.25-3.36-9.98Z"
        />
        <motion.path
          variants={draw}
          custom={21}
          d="M544.79,523.21c-3.2-.73-6.88,2.56-8.23,7.34-1.34,4.78,.16,9.25,3.36,9.98s6.88-2.56,8.23-7.34c1.34-4.78-.16-9.25-3.36-9.98Z"
        />
        <motion.path
          variants={draw}
          custom={22}
          d="M456.03,320.84c.21,.55,1.32,3.63,1.31,3.75-.42,14.41-25.55,26.03-56.49,26.03s-56.5-11.82-56.5-26.4"
        />
        <motion.path
          variants={draw}
          custom={23}
          d="M345.64,320.97c-.21,.55-1.32,3.63-1.31,3.75,.42,14.41,25.55,26.03,56.49,26.03s56.5-11.82,56.5-26.4"
        />
        <motion.path
          variants={draw}
          custom={24}
          d="M355.6,296.23c-.18,.42-1.08,2.81-1.08,2.9,.34,11.13,20.98,20.11,46.38,20.11s46.38-9.13,46.38-20.39"
        />
        <motion.path
          variants={draw}
          custom={25}
          d="M400.84,389.57c5.02,0,9.11-1.91,9.11-4.25s-4.09-4.25-9.11-4.25-9.11,1.91-9.11,4.25,4.09,4.25,9.11,4.25Z"
        />
        <motion.path
          variants={draw}
          custom={26}
          d="M392.66,267.41c-.6-.57-.94-1.21-.94-1.88,0-2.35,4.08-4.25,9.11-4.25s9.11,1.9,9.11,4.25c0,.46-.16,.9-.44,1.31"
        />
        <motion.path
          variants={draw}
          custom={27}
          d="M509.9,331.47c5.02,0,9.11-1.91,9.11-4.25s-4.09-4.25-9.11-4.25-9.11,1.91-9.11,4.25,4.09,4.25,9.11,4.25h0Z"
        />
        <motion.path
          variants={draw}
          custom={28}
          d="M291.78,331.47c5.02,0,9.11-1.91,9.11-4.25s-4.09-4.25-9.11-4.25-9.11,1.91-9.11,4.25,4.09,4.25,9.11,4.25Z"
        />
        <motion.path
          variants={draw}
          custom={29}
          d="M400.85,290.01c12.57,0,22.76,4.76,22.76,10.64,0,1.16-.4,2.27-1.13,3.32-6.3,1.8-13.71,2.84-21.63,2.84s-15.33-1.04-21.63-2.84c-.73-1.04-1.13-2.16-1.13-3.32,0-5.87,10.19-10.64,22.76-10.64Z"
        />
        <motion.line
          variants={draw}
          custom={30}
          x1="487.52"
          y1="366.4"
          x2="463.07"
          y2="352.28"
        />
        <motion.line
          variants={draw}
          custom={31}
          x1="465.87"
          y1="277.28"
          x2="444.04"
          y2="289.89"
        />
        <motion.line
          variants={draw}
          custom={32}
          x1="334.49"
          y1="278.04"
          x2="357.75"
          y2="291.47"
        />
        <motion.polygon
          variants={draw}
          custom={33}
          points="548.63 421.38 557.89 416.04 557.89 437.56 548.63 442.91 548.63 421.38"
        />
        <motion.polygon
          variants={draw}
          custom={34}
          points="548.63 465.81 557.89 460.46 557.89 481.99 548.63 487.33 548.63 465.81"
        />
        <motion.path
          variants={draw}
          custom={35}
          d="M394.79,536.39l-48-27.71m-72.34-41.77l-40.26-23.25"
        />
        <motion.path
          variants={draw}
          custom={36}
          d="M363.52,440.1c3.2-.73,6.88,2.56,8.23,7.34,1.34,4.79-.16,9.25-3.36,9.98-3.2,.73-6.88-2.56-8.23-7.34-1.34-4.79,.16-9.25,3.36-9.98Z"
        />
        <motion.path
          variants={draw}
          custom={37}
          d="M363.52,583.44c3.2-.73,6.88,2.56,8.23,7.34,1.34,4.78-.16,9.25-3.36,9.98-3.2,.73-6.88-2.56-8.23-7.34-1.34-4.78,.16-9.25,3.36-9.98Z"
        />
        <motion.path
          variants={draw}
          custom={38}
          d="M256.88,523.21c3.2-.73,6.88,2.56,8.23,7.34,1.34,4.78-.16,9.25-3.36,9.98-3.2,.73-6.88-2.56-8.23-7.34-1.34-4.78,.16-9.25,3.36-9.98h0Z"
        />
        <motion.polygon
          variants={draw}
          custom={39}
          points="382.45 496.1 373.19 490.75 373.19 512.28 382.45 517.62 382.45 496.1"
        />
        <motion.polygon
          variants={draw}
          custom={40}
          points="382.45 540.53 373.19 535.18 373.19 556.7 382.45 562.05 382.45 540.53"
        />
        <motion.path
          variants={draw}
          custom={1}
          d="M370.42,398.95l-92.03-53.25c-16.73-9.68-14.63-26.74,4.67-37.9l82.7-47.85c20.84-12.06,49.33-12.06,70.17,0l82.7,47.85c19.3,11.17,21.4,28.22,4.67,37.9l-92.03,53.25c-15.41,8.92-45.42,8.92-60.84,0h0Z"
        />
        <motion.path
          variants={draw}
          custom={42}
          d="M364.36,296.92c2.71-9.31,18.02-16.45,36.5-16.45s33.75,7.12,36.48,16.41"
        />
        <motion.line
          variants={draw}
          custom={43}
          x1="509.17"
          y1="407.34"
          x2="509.17"
          y2="370.23"
        />
        <motion.line
          variants={draw}
          custom={44}
          x1="467.73"
          y1="430.83"
          x2="467.73"
          y2="393.72"
        />
        <motion.polyline
          variants={draw}
          custom={45}
          points="502.73 410.74 496.46 405.43 482.99 413.07 476.68 425.5"
        />
        <motion.polyline
          variants={draw}
          custom={45}
          points="475.14 567.92 481.41 573.22 494.88 565.58 501.19 553.15"
        />
        <motion.line
          variants={draw}
          custom={47}
          x1="467.38"
          y1="605.79"
          x2="467.38"
          y2="571.98"
        />
        <motion.polyline
          variants={draw}
          custom={48}
          points="536.4 494.13 538.23 490.53 538.38 432.08 509.17 407.34 467.73 430.83 438.32 488.79 438.18 547.24 467.38 571.98 508.82 548.47 516.67 532.99"
        />
        <motion.line
          variants={draw}
          custom={49}
          x1="508.82"
          y1="581.08"
          x2="508.82"
          y2="548.49"
        />
        <motion.path
          variants={draw}
          custom={11}
          d="M531.49,356.47c-14.75,8.51-29.52,17.05-44.28,25.57-14.76,8.52-29.52,17.04-44.29,25.57-19.8,11.43-36.04,42.21-36.04,68.33v116.85c0,26.12,16.22,38.09,36.01,26.67,14.79-8.54,29.57-17.06,44.32-25.59,14.76-8.52,29.55-17.06,44.31-25.58,19.8-11.43,35.96-42.08,35.96-68.2v-116.89c0-26.12-16.17-38.17-35.99-26.73h0Z"
        />
        <motion.polyline
          variants={draw}
          custom={51}
          points="265.27 494.13 263.45 490.53 263.29 432.08 292.5 407.34 333.94 430.83 363.35 488.79 363.49 547.24 334.29 571.98 292.85 548.47 285 532.99"
        />
        <motion.line
          variants={draw}
          custom={52}
          x1="333.94"
          y1="430.83"
          x2="333.94"
          y2="393.72"
        />
        <motion.path
          variants={draw}
          custom={2}
          d="M270.18,356.47c14.75,8.51,29.52,17.05,44.28,25.57,14.76,8.52,29.52,17.04,44.29,25.57,19.8,11.43,36.04,42.21,36.04,68.33v116.85c0,26.12-16.22,38.09-36.01,26.67-14.79-8.54-29.57-17.06-44.32-25.59-14.76-8.52-29.55-17.06-44.31-25.58-19.8-11.43-35.96-42.08-35.96-68.2v-116.89c0-26.12,16.17-38.17,35.99-26.73h0Z"
        />
        <motion.line
          variants={draw}
          custom={54}
          x1="292.5"
          y1="407.34"
          x2="292.5"
          y2="370.23"
        />
        <motion.line
          variants={draw}
          custom={55}
          x1="334.29"
          y1="605.79"
          x2="334.29"
          y2="571.98"
        />
        <motion.line
          variants={draw}
          custom={56}
          x1="292.85"
          y1="581.08"
          x2="292.85"
          y2="548.49"
        />
        <motion.polyline
          variants={draw}
          custom={57}
          points="324.99 567.04 318.73 572.35 305.25 564.71 298.95 552.28"
        />
        <motion.line
          variants={draw}
          custom={58}
          x1="312.84"
          y1="365.64"
          x2="337.25"
          y2="351.54"
        />
        <motion.path
          variants={draw}
          custom={59}
          d="M256.88,379.86c3.2-.73,6.88,2.56,8.23,7.34,1.34,4.78-.16,9.25-3.36,9.98-3.2,.73-6.88-2.56-8.23-7.34-1.34-4.78,.16-9.25,3.36-9.98h0Z"
        />
        <motion.polyline
          variants={draw}
          custom={60}
          points="298.95 410.74 305.21 405.43 318.68 413.07 324.99 425.5"
        />
        <motion.path
          variants={draw}
          custom={61}
          d="M448.21,358.53l-3.05-9.76c5.9-2.12,13.21-5.7,16.74-8.97l13.42,3.77"
        />
        <motion.path
          variants={draw}
          custom={62}
          d="M443.38,287.24c28.87,7.61,48.54,22.72,48.54,40.11,0,25.05-40.78,45.35-91.08,45.35s-91.08-20.31-91.08-45.35c0-17.73,20.43-33.08,50.2-40.54"
        />
        <motion.path
          variants={draw}
          custom={63}
          d="M445.68,294.6c22.67,6.87,37.67,18.8,37.67,32.36,0,21.29-36.94,38.55-82.51,38.55s-82.51-17.26-82.51-38.55c0-13.44,14.72-25.27,37.03-32.17"
        />
        <motion.path
          variants={draw}
          custom={64}
          d="M449.44,303.38c2.65,1.44,5.01,2.97,6.6,4.44l11.85-3.33"
        />
        <motion.path
          variants={draw}
          custom={65}
          d="M352.23,303.38c-2.65,1.44-5.01,2.97-6.6,4.44l-11.85-3.33"
        />
        <motion.path
          variants={draw}
          custom={66}
          d="M353.46,358.53l3.05-9.76c-5.9-2.12-13.21-5.7-16.74-8.97l-13.42,3.77"
        />
        <motion.line
          variants={draw}
          custom={67}
          x1="359.57"
          y1="285.2"
          x2="344.96"
          y2="322.84"
        />
        <motion.line
          variants={draw}
          custom={68}
          x1="442.1"
          y1="285.07"
          x2="456.71"
          y2="322.71"
        />
        <motion.path
          variants={draw}
          custom={37}
          d="M400.85,290.01c12.57,0,22.76,4.76,22.76,10.64,0,1.16-.4,2.27-1.13,3.32-6.3,1.8-13.71,2.84-21.63,2.84s-15.33-1.04-21.63-2.84c-.73-1.04-1.13-2.16-1.13-3.32,0-5.87,10.19-10.64,22.76-10.64Z"
        />
        <motion.path
          variants={draw}
          custom={70}
          d="M400.85,306.8c22.88,0,41.54-8.72,41.54-19.41s-18.66-19.41-41.54-19.41-41.54,8.72-41.54,19.41,18.66,19.41,41.54,19.41Z"
        />
        <motion.path
          variants={draw}
          custom={71}
          d="M438.12,621.79c-21.3,9.82-55.63,10.67-75.84-.54"
        />
        <motion.path
          variants={draw}
          custom={37}
          d="M423.46,515.95h0c1.35-.78,2.58-1.06,3.46-.89,.89,.17,1.44,.79,1.44,1.82v14.08c0,1.03-.55,2.29-1.44,3.49-.89,1.19-2.12,2.32-3.46,3.1h0c-1.35,.78-2.58,1.07-3.47,.9s-1.44-.79-1.44-1.83v-14.08c0-1.03,.55-2.29,1.44-3.49,.89-1.19,2.12-2.33,3.47-3.11h0Z"
        />
        <motion.path
          variants={draw}
          custom={22}
          d="M248.85,441.32h0c-1.35-.78-2.58-1.06-3.46-.89-.89,.17-1.44,.79-1.44,1.82v14.08c0,1.03,.55,2.29,1.44,3.49,.89,1.19,2.12,2.32,3.46,3.1h0c1.35,.78,2.58,1.07,3.47,.9s1.44-.79,1.44-1.83v-14.08c0-1.03-.55-2.29-1.44-3.49-.89-1.19-2.12-2.33-3.47-3.1Z"
        />
        <motion.path
          variants={draw}
          custom={3}
          d="M307.61,535.63l-.02-.04c.37-.2,.73-.42,1.08-.66l11.69-6.72c13.65-7.85,13.92-27.9,10.18-41.24-5.37-19.13-19.46-32.67-32.46-31.9-3.31,.2-5.48,1.18-8.37,2.76l-11.56,6.3c-.3,.16-.66,.43-1.04,.73,2.74-13.12,10.1-22.86,20.78-25.29,18.23-4.15,39.22,14.57,46.87,41.83,7.65,27.26-.93,52.72-19.16,56.88-5.89,1.34-12.07,.3-17.99-2.65h0Z"
        />
        <motion.line
          variants={draw}
          custom={75}
          x1="291.87"
          y1="514.11"
          x2="305.43"
          y2="501.21"
        />
        <motion.path
          variants={draw}
          custom={76}
          d="M318.43,520.96c-1.67,1.3-3.56,2.23-5.66,2.71-11.47,2.61-24.66-9.16-29.47-26.3-4.15-14.8-.7-28.75,7.7-33.97"
        />
        <motion.path
          variants={draw}
          custom={77}
          d="M319.6,510.2c-.98,.63-2.05,1.08-3.21,1.35-8.13,1.85-17.5-6.5-20.91-18.66-3-10.7-.4-20.77,5.81-24.31,7.06,5.63,13.1,14.84,16.21,25.93,1.52,5.41,2.19,10.73,2.1,15.69Z"
        />
        <motion.line
          variants={draw}
          custom={78}
          x1="304.8"
          y1="492.83"
          x2="284.95"
          y2="502.28"
        />
        <motion.line
          variants={draw}
          custom={79}
          x1="297.79"
          y1="486.44"
          x2="281.76"
          y2="489.38"
        />
        <motion.line
          variants={draw}
          custom={80}
          x1="298.36"
          y1="478.9"
          x2="282.13"
          y2="478.22"
        />
        <motion.line
          variants={draw}
          custom={81}
          x1="300.3"
          y1="472.87"
          x2="286.47"
          y2="469.05"
        />
        <motion.path
          variants={draw}
          custom={35}
          d="M318.45,502.19c-.64,.41-1.34,.71-2.1,.88-5.32,1.21-11.43-4.25-13.66-12.2-1.96-6.99-.26-13.58,3.8-15.88,4.62,3.68,8.56,9.7,10.6,16.95,.99,3.54,1.43,7.01,1.37,10.25h0Z"
        />
        <motion.line
          variants={draw}
          custom={83}
          x1="300.77"
          y1="521.65"
          x2="310.67"
          y2="506.55"
        />
        <motion.line
          variants={draw}
          custom={84}
          x1="310.15"
          y1="524.02"
          x2="315.59"
          y2="508.33"
        />
        <motion.path
          variants={draw}
          custom={85}
          d="M282.21,463.01c13.73-3.13,29.53,10.97,35.29,31.49,5.76,20.52-.7,39.69-14.43,42.82s-29.53-10.97-35.29-31.49c-5.76-20.52,.7-39.69,14.43-42.82Z"
        />
        <motion.path
          variants={draw}
          custom={86}
          d="M313.53,529.34c-1.25,.68-2.6,1.19-4.03,1.51-12.89,2.94-27.71-10.3-33.12-29.56-4.61-16.42-.88-31.91,8.29-37.95"
        />
        <motion.path
          variants={draw}
          custom={4}
          d="M298.08,455.07c13-.78,27.09,12.77,32.46,31.9,3.74,13.34,3.47,33.4-10.18,41.24l-11.69,6.72c-1.72,1.13-3.58,1.94-5.6,2.4-13.73,3.13-29.53-10.97-35.29-31.49-4.7-16.74-1.26-32.58,7.61-39.55,.82-.64,2-1.74,2.75-2.15l11.56-6.3c2.89-1.58,5.06-2.57,8.37-2.76h.01Z"
        />
      </motion.svg>
    </Div>
  );
}
