import {
  motion,
  useScroll,
  useMotionTemplate,
  useTransform,
  useMotionValue,
  useTime,
} from "framer-motion";

import { useRef, useState, useEffect } from "react";

export function FloatingModule({ z, src, offset, top, left, opacity = 0.3 }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "start start"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [0, -(z / 100) * 2000]);
  const s = useMotionValue(z / 200);
  const rot = Math.floor(Math.random() * 20) - 10;
  const transform = useMotionTemplate` scale(${s}) translateY(${y}px) `;
  const time = useTime();
  const rotate = useTransform(
    time,
    [0, 30000 * rot], // For every 40 seconds...
    [0, 360], // ...rotate 360deg
    { clamp: false }
  );

  const transformimg = useMotionTemplate` rotate(${rotate}deg) `;

  return (
    <motion.div
      ref={ref}
      style={{
        position: "absolute",
        width: "400px",
        height: "400px",
        left,
        top,
        transform,
        zIndex: z,
        opacity,
      }}
    >
      <motion.img
        src={src}
        style={{
          position: "absolute",
          transform: transformimg,
          zIndex: z,
          transformOrigin: `50% 50%`,
        }}
      />
    </motion.div>
  );
}
