import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Icon,
  useColorModeValue,
  createIcon,
  Center,
  Image,
  Wrap,
  Flex,
} from "@chakra-ui/react";
import { SimpleGrid, HStack, VStack } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import {
  motion,
  useScroll,
  useMotionTemplate,
  useTransform,
  useMotionValue,
} from "framer-motion";
import { Draw } from "./Draw";
import { useRef, useState, useEffect } from "react";
import { Link } from "@chakra-ui/react";
import test1 from "./assets/test1.svg";
import test2 from "./assets/test2.svg";
import test3 from "./assets/test3.svg";
import test4 from "./assets/test4.svg";
import chroma from "chroma-js";
import { LogoAnim } from "./LogoAnim";
import anvillogo from "./assets/anvillogo.svg";
import base_inside from "./assets/base_inside.svg";
import { CodeBox } from "./CodeBox";
import * as code from "./code";
import powered from "./assets/powered.svg";
import icblast from "./assets/icblast.svg";
import blast_screenshot from "./assets/blast_screenshot.jpg";
import anvil_screenshot from "./assets/anvil_screenshot.jpg";
import azle from "./assets/azle.svg";
import sns from "./assets/sns.png";
import { OptInForm } from "./Optin";
import { AnimatedBackground } from "./AnimatedBackground";
import assembled_base from "./assets/assembled_base.svg";
import { FloatingModule } from "./FloatingModule";
import { CC, CCmodule } from "./CaelumCore";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIcon } from "@chakra-ui/icons";

import asteroid1 from "./assets/asteroids/2.png";
import asteroid2 from "./assets/asteroids/4.png";
import asteroid3 from "./assets/asteroids/7.png";
import asteroid4 from "./assets/asteroids/8.png";
import asteroid5 from "./assets/asteroids/5.png";

export function Page() {
  const { scrollY, scrollYProgress } = useScroll();
  const navigate = useNavigate();

  return (
    <Center>
      {/* ---- */}
      <Stack spacing="20" ml="10px" mr="10px">
        {/* ---- */}

        <Stack maxW="1200px">
          <Center>
            <Box
              maxW="800px"
              sx={{
                position: "relative",
                fontWeight: "bold",
                fontFamily: "Greycliff",
                fontSize: "20px",
              }}
            >
              {/* <Text
                sx={{ fontFamily: "Greycliff", fontSize: "30px" }}
                mb="20px"
              >
                Introducing Next-Gen Decentralized App Development
              </Text> */}

              {/* <Wrap spacing="0"> */}
              <Box
                // w={"70%"}
                sx={{
                  fontFamily: "Greycliff",
                  fontWeight: "normal",
                  fontSize: "18px",
                }}
                mb="20px"
                pr="10px"
              >
                {/* Welcome to Internet Base. */}
                &nbsp;
                <br />
                <br />
                <Box sx={{ position: "relative", left: "-400px" }}>
                  <FloatingModule
                    src={asteroid1}
                    left={150}
                    top={20}
                    z={30}
                    offset={scrollYProgress}
                    opacity={1}
                  />
                  <FloatingModule
                    src={asteroid2}
                    left={300}
                    top={30}
                    z={60}
                    offset={scrollYProgress}
                    opacity={1}
                  />
                  <FloatingModule
                    src={asteroid3}
                    left={300}
                    top={100}
                    z={50}
                    offset={scrollYProgress}
                    opacity={1}
                  />
                  <FloatingModule
                    src={asteroid4}
                    left={240}
                    top={250}
                    z={30}
                    offset={scrollYProgress}
                    opacity={1}
                  />
                </Box>
                <Box sx={{ position: "relative", right: "-500px" }}>
                  <FloatingModule
                    src={asteroid3}
                    left={100}
                    top={300}
                    z={40}
                    offset={scrollYProgress}
                    opacity={1}
                  />
                  <FloatingModule
                    src={asteroid4}
                    left={120}
                    top={-140}
                    z={20}
                    offset={scrollYProgress}
                    opacity={1}
                  />
                  <FloatingModule
                    src={asteroid1}
                    left={-40}
                    top={100}
                    z={30}
                    offset={scrollYProgress}
                    opacity={1}
                  />
                  <FloatingModule
                    src={asteroid2}
                    left={40}
                    top={280}
                    z={80}
                    offset={scrollYProgress}
                    opacity={1}
                  />
                </Box>
                <Center>
                  <Image src={assembled_base} maxW="80vw" />
                </Center>
                {/* <br />
                <br />
                Our Decentralized Assembly Platform is engineered to facilitate
                the smooth combination of modular elements, granting architects
                access to a vast collection of top-tier modules developed by
                creators and service providers across the globe. By nurturing a
                dynamic ecosystem, DAP enables architects to modify and expand
                these modules to fulfill their unique needs while integrating
                their custom code. The outcome is an intimately interconnected
                digital experience, transcending borders and reimagining the
                scope of possibilities within the decentralized landscape. */}
              </Box>
              {/* <Center>
                <Box
                  maxW="90vw"
                  mt="50px"
                  borderRadius={"20px"}
                  pl="3vw"
                  pt="10"
                  pr="3vw"
                  pb="10"
                  border="1px solid #aa5aee"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/caelumcore");
                    window.scrollTo(0, 0);
                  }}
                >
                  <HStack>
                    <Image src={CCmodule} w={"100%"} maxW="10vw" />
                    <Stack spacing="-4">
                      <Text pb="2vh">Discover</Text>
                      <CC fontSize="3vh" />
                      <Text pt="1vh" fontSize="3vh" fontWeight="bold">
                        Framework
                      </Text>
                    </Stack>
                    <ArrowForwardIcon color="#aa5aee" w="40px" h="40px" />
                  </HStack>
                </Box>
              </Center> */}
            </Box>
          </Center>
        </Stack>
      </Stack>
    </Center>
  );
}
