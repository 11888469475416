import {
  Box,
  Heading,
  Container,
  Text,
  Button,
  Stack,
  Icon,
  useColorModeValue,
  createIcon,
  Center,
  Image,
  Wrap,
  Flex,
} from "@chakra-ui/react";

import {
  motion,
  useScroll,
  useMotionTemplate,
  useTransform,
  useMotionValue,
} from "framer-motion";

import { useRef, useState, useEffect } from "react";

import base_inside from "./assets/base_inside.svg";

export function AnimatedBackground() {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    // target: ref,
    // offset: ["start end", "start end"],
  });

  const y = useTransform(scrollYProgress, [0, 1], [0, -1000]);
  const opacity = useTransform(scrollYProgress, [0, 0.9, 1], [0.1, 0.02, 0.1]);

  const transform = useMotionTemplate` translateX(${y}px) `;
  // const [isResizing, setIsResizing] = useState(false);

  // useEffect(() => {
  //   let timer;
  //   const handleResizeStart = () => {
  //     if (!isResizing) setIsResizing(true);
  //     clearTimeout(timer);
  //     timer = setTimeout(handleResizeEnd, 500);
  //   };

  //   const handleResizeEnd = () => {
  //     setIsResizing(false);
  //   };

  //   window.addEventListener("resize", handleResizeStart);

  //   return () => {
  //     window.removeEventListener("resize", handleResizeStart);
  //     clearTimeout(timer);
  //   };
  // }, []);

  // if (isResizing) {
  //   return null;
  // }

  // const transform = useMotionTemplate` translateX(${x}px) `;

  return (
    <motion.div
      ref={ref}
      style={{
        // width: "4000px",
        height: "100vh",
        display: "block",
        position: "fixed",
        top: 0,
        // height: "100%",
        right: -1000,
        // width: "5000px",
        zIndex: -1,
        // objectPosition: "left",
        left: 0,
        transform,
        opacity,
        transition: "transform 5000ms linear, opacity 5000ms linear",
      }}
    >
      <img
        src={base_inside}
        style={{
          // position: "fixed",
          // opacity: 1,
          height: "100%",
          objectFit: "cover",
        }}
        alt=""
      />
    </motion.div>
  );
}
